import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ThirdPartyTransactionStatus } from '@deliver-sense-librarian/data-schema';
import { MatDialog, MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { FirebaseAuthService } from 'app/auth/services/firebase-auth.service';
import { Papa } from 'ngx-papaparse';
import { FormBuilder, FormGroup, FormArray, FormControl, Validators } from '@angular/forms';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { AngularFirestore } from '@angular/fire/firestore';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-tpd-statuses',
  templateUrl: './tpd-statuses.component.html',
  styleUrls: ['./tpd-statuses.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TpdStatusesComponent implements OnInit, OnDestroy {
  @Input() thirdPartyId: string;
  thirdPartyStatuses: ThirdPartyTransactionStatus[] = []
  destroy$ = new Subject();
  thirdPartyStatusForm: FormGroup;
  constructor(
    private auth: FirebaseAuthService,
    private papa: Papa,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.fetchTpdStatuses();
  }
  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }
  private fetchTpdStatuses() {
    this.afs.collection('thirdPartyTransactionStatuses', ref => ref
      .where('thirdParty', '==', this.thirdPartyId))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(result$ => {
        this.thirdPartyStatuses = <ThirdPartyTransactionStatus[]>FirestoreUtilities.mapToType(result$);
        this.setupThirdPartyStatusForm();
      })
  }
  private setupThirdPartyStatusForm() {
    this.thirdPartyStatusForm = this.fb.group({
      statuses: new FormArray([])
    });
    this.populateStatues();
  }
  private populateStatues() {
    const statusFormArray = this.thirdPartyStatusForm.get('statuses') as FormArray;
    this.thirdPartyStatuses.forEach((thirdPartyStatus: ThirdPartyTransactionStatus) => {
      statusFormArray.push(new FormGroup({
        status: new FormControl(thirdPartyStatus.status, Validators.required),
        thirdParty: new FormControl(thirdPartyStatus.thirdParty, Validators.required),
        id: new FormControl(thirdPartyStatus.id, Validators.required)
      }));
    })
  }
  public addFormArrayGroup() {
    const statusFormArray = this.thirdPartyStatusForm.get('statuses') as FormArray;
    statusFormArray.push(new FormGroup({
      status: new FormControl('', Validators.required),
      thirdParty: new FormControl(this.thirdPartyId, Validators.required),
      id: new FormControl(this.afs.createId(), Validators.required)
    }));
  }

  public removeFormArrayGroup(index) {
    const methodParameters = this.thirdPartyStatusForm.get('statuses') as FormArray;
    methodParameters.removeAt(index);
  }
  public async saveStatuses() {
    if (this.thirdPartyStatusForm.valid) {
      const statusArray = this.thirdPartyStatusForm.get('statuses').value;
      const statusSaveRequests = statusArray.map((statusGroup: { status, id, thirdParty }) => {
        const thirdPartyTransactionStatus = new ThirdPartyTransactionStatus();
        thirdPartyTransactionStatus.thirdParty = statusGroup.thirdParty;
        thirdPartyTransactionStatus.status = statusGroup.status;
        return this.afs.doc(`thirdPartyTransactionStatuses/${statusGroup.id}`).set(thirdPartyTransactionStatus.toJSONObject());
      });
      try {
        await Promise.all(statusSaveRequests);
        this.snackBar.open('Statuses saved successfully!', 'Dismiss', { duration: 5000 });
      } catch (e) {
        this.snackBar.open('Oops... something went wrong. Please refresh and try again', 'Dismiss', { duration: 5000 });
      }
    } else {
      this.snackBar.open('Please set the status value for each configuration', 'Dismiss', { duration: 5000 });
    }
  }
}
