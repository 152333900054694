import { Injectable } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { AngularFireAuth } from "@angular/fire/auth";
import { User } from "@deliver-sense-librarian/data-schema";
import { FirebaseAuthService } from "../auth/services/firebase-auth.service";

@Injectable()
export class AccessControlService {

    currentUser: User;

    constructor(private afs: AngularFirestore,
                private auth: FirebaseAuthService) {

    }

}
