import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FirebaseAuthService} from "../../../../../auth/services/firebase-auth.service";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {
  Client,
  ClientPosCategory, PosSystem,
  RateType
} from "@deliver-sense-librarian/data-schema";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../../utilities/firestore-utilities";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
  selector: 'app-client-category-rate-type-mappings',
  templateUrl: './client-category-rate-type-mapping.component.html',
  styleUrls: ['./client-category-rate-type-mapping.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientCategoryRateTypeMappingComponent implements OnInit {


  @Input() client: Client;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<ClientPosCategory>;
  public displayedColumns: string[] = ['code', 'rateType', 'pos', 'edit', 'expandTrigger'];
  public expandedElement: any | null;
  public clientPosCategoryInEdit: BehaviorSubject<ClientPosCategory> = new BehaviorSubject(null);
  private _destroy$ = new Subject();
  public categoryRateTypeForm: FormGroup;
  private rateTypes: RateType[] = [];
  private clientPosCategories: ClientPosCategory[] = [];
  private posSystems: PosSystem[] = [];

  constructor(private auth: FirebaseAuthService,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this._getRateTypes();
    this._getAvailablePos();
    this._getClientPosCategoriess();
  }

  private _getClientPosCategoriess() {
    this.afs.collection('clientPosCategories', ref => ref
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(clientPosCategories$ => {
        this.clientPosCategories = <ClientPosCategory[]>FirestoreUtilities.mapToType(clientPosCategories$);
        this.tableData = new MatTableDataSource(this.clientPosCategories);
      })
  }

  public getPosName(clientPosCategory: ClientPosCategory) {
    const pos = this.posSystems.find(_pos => _pos.id === clientPosCategory.posSystem);
    return pos ? pos.name : '';
  }

  public getRateTypeName(clientPosCategory: ClientPosCategory) {
    const rateType = this.rateTypes.find(_rateType => _rateType.id === clientPosCategory.rateType);
    return rateType ? rateType.name : '';
  }

  private _getAvailablePos() {
    this.afs.collection('posSystems')
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(pos$ => {
        this.posSystems = <PosSystem[]>FirestoreUtilities.mapToType(pos$);
      });
  }

  public setMappingInEdit(posCategory?: ClientPosCategory) {
    this.clientPosCategoryInEdit.next(posCategory ? posCategory : new ClientPosCategory());
    this.setupMappingForm();
  }

  private setupMappingForm() {
    this.clientPosCategoryInEdit.subscribe((posCategory$: ClientPosCategory) => {
      if (posCategory$) {
        this.categoryRateTypeForm = this.fb.group({
          posSystem: new FormControl(posCategory$.posSystem, Validators.required),
          code: new FormControl(posCategory$.code, Validators.required),
          rateType: new FormControl(posCategory$.rateType, Validators.required),
        });
      }
    })
  }


  public cancel() {
    this.categoryRateTypeForm = null;
    this.clientPosCategoryInEdit.next(null);
  }

  async save() {
    if (this.categoryRateTypeForm.valid) {
      const clientPosCategory = this.mapFormToConversion(this.categoryRateTypeForm.value);
      if (this.clientPosCategoryInEdit.value.id) {
        await this.afs.doc(`clientPosCategories/${this.clientPosCategoryInEdit.value.id}`).update(
          clientPosCategory.toJSONObject()
        )
      } else {
        await this.afs.collection(`clientPosCategories`).add(
          clientPosCategory.toJSONObject()
        )
      }
      this.snackBar.open(`Conversion ${this.clientPosCategoryInEdit.value.id ? 'Updated' : 'Created'}`, 'Dismiss', {
        duration: 5000
      });
      this.categoryRateTypeForm = null;
      this.clientPosCategoryInEdit.next(null);
    } else {
      this.snackBar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private mapFormToConversion(formValues) {
    const newCategory = new ClientPosCategory();
    newCategory.posSystem = formValues.pos;
    newCategory.code = formValues.code;
    newCategory.rateType = formValues.rateType;
    newCategory.client = this.client.id;
    return newCategory;
  }

  private _getRateTypes() {
    return this.afs.collection('rateTypes')
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(rateType$ => {
        this.rateTypes = <RateType[]>FirestoreUtilities.mapToType(rateType$);
      });
  }
}
