import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { ThirdParty } from '@deliver-sense-librarian/data-schema';
import { FirebaseAuthService } from 'app/auth/services/firebase-auth.service';
import { MatDialog, MatSnackBar, MatSelectChange } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { takeUntil, first } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-tpd-mappings',
  templateUrl: './tpd-mappings.component.html',
  styleUrls: ['./tpd-mappings.component.scss']
})
export class TpdMappingsComponent implements OnInit, OnDestroy {
  public selectedThirdPartyId: string;
  public thirdParties: ThirdParty[] = [];
  private destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.afs.collection('thirdParties')
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(thirdParties$ => {
        this.thirdParties = <ThirdParty[]>FirestoreUtilities.mapToType(thirdParties$);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  setThirdParty($event: MatSelectChange) {
    this.selectedThirdPartyId = null;
    setTimeout(() => {
      this.selectedThirdPartyId = $event.value;
    })
  }
}
