import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataConverterComponent } from './data-converter/data-converter.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../../material-module.module";
import { ScripterComponent } from './scripter/scripter.component';
import { FireqlComponent } from './fireql/fireql.component';
import {DsSharedModule} from "../../shared/ds-shared.module";
import { QueryResultsComponent } from './fireql/query-results/query-results.component';
import {MonacoEditorModule, NgxMonacoEditorConfig} from "ngx-monaco-editor";
const monacoConfig: NgxMonacoEditorConfig = {
  defaultOptions: { theme: 'vs-dark', language: 'javascript', }, // pass default options to be used
  onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};
@NgModule({
  declarations: [DataConverterComponent, ScripterComponent, FireqlComponent, QueryResultsComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormsModule,
        RouterModule,
        FlexLayoutModule,
        MaterialModule,
        DsSharedModule,
        MonacoEditorModule.forRoot(monacoConfig),
    ]
})
export class DevToolsModule { }
