import {Component, OnDestroy, OnInit} from '@angular/core';
import {takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../utilities/firestore-utilities";
import {FirebaseAuthService} from "../../auth/services/firebase-auth.service";
import {MatDialog} from "@angular/material/dialog";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {Subject} from "rxjs";

@Component({
  selector: 'app-client',
  templateUrl: './client.component.html',
  styleUrls: ['./client.component.scss']
})
export class ClientComponent implements OnInit, OnDestroy {
  public client: any;
  private _destroy$ = new Subject();
  links = [
    {name: 'Organization', route: 'organization'},
    {name: 'Billing', route: 'billing'},
    {name: 'Users', route: 'users'},
    {name: '3PD', route: 'module-3pd'},
    {name: 'Audit Trails', route: 'module-at'},
    {name: 'Settings', route: 'settings'},
    {name: 'Dev', route: 'api'}];
  activeLink: string;

  constructor(private auth: FirebaseAuthService,
              private dialog: MatDialog,
              private router: Router,
              public activatedRoute: ActivatedRoute,
              private snackBar: MatSnackBar,
              private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.activatedRoute.params.subscribe(params$ => {
      this.afs.doc(`clients/${params$['id']}`)
        .snapshotChanges()
        .pipe(takeUntil(this._destroy$))
        .subscribe(client$ => {
          this.client = FirestoreUtilities.objectToType(client$);
        })
    });
  }

  isActive(route) {
    // this.activeLink = this.activatedRoute.snapshot.url[this.activatedRoute.snapshot.url.length - 1].path;
    // console.log(this.activeLink);
    // return route === this.activeLink;
  }
  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  goTo(link: string) {
    this.router.navigate([`../${link}`]);
  }
}
