import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {OptionsComponent} from "./options.component";
import {MaterialModule} from "../../../material-module.module";
import {FlexLayoutModule} from "@angular/flex-layout";

@NgModule({
  declarations: [OptionsComponent],
  exports: [OptionsComponent],
  imports: [
    MaterialModule,
    FlexLayoutModule,
    CommonModule
  ]
})
export class OptionsModule { }
