import { Component, OnInit, Input } from '@angular/core';
import { Client } from '@deliver-sense-librarian/data-schema';

@Component({
  selector: 'app-client-invoice-credits',
  templateUrl: './client-invoice-credits.component.html',
  styleUrls: ['./client-invoice-credits.component.scss']
})
export class ClientInvoiceCreditsComponent implements OnInit {
  @Input() client: Client;
  constructor() { }

  ngOnInit() {
  }

}
