import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {RouterModule} from "@angular/router";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../../material-module.module";
import {ClientListComponent} from './client-list/client-list.component';
import {Client3pdComponent} from './client/client-3pd/client-3pd.component';
import {ClientConversionManagerComponent} from "./client/client-3pd/client-conversion-manager/client-conversion-manager.component";
import {ClientCategoryRateTypeMappingComponent} from "./client/client-3pd/client-category-rate-type-mapping/client-category-rate-type-mapping.component";
import {DsSharedModule} from "../../shared/ds-shared.module";
import { ClientBillingComponent } from './client/client-billing/client-billing.component';
import { PosHouseAccountMappingsComponent } from './client/client-3pd/pos-house-account-mappings/pos-house-account-mappings.component';
import { ClientUsersComponent } from './client/client-organization/client-users/client-users.component';
import { ClientLocationsComponent } from './client/client-organization/client-locations/client-locations.component';
import { ClientApiComponent } from './client/client-api/client-api.component';
import { ClientSettingsComponent } from './client/client-settings/client-settings.component';
import { ClientVendorMappingsComponent } from './client/client-3pd/client-vendor-mappings/client-vendor-mappings.component';
import {MonacoEditorModule, NgxMonacoEditorConfig} from "ngx-monaco-editor";
import { ClientConversionComponent } from './client/client-3pd/client-conversion/client-conversion.component';
import { ClientDayChargesComponent } from './client/client-billing/client-day-charges/client-day-charges.component';
import { ClientInvoicesComponent } from './client/client-billing/client-invoices/client-invoices.component';
import { ClientInvoiceCreditsComponent } from './client/client-billing/client-invoice-credits/client-invoice-credits.component';
import { TpdInitializeComponent } from './client/client-3pd/tpd-initialize/tpd-initialize.component';
import { ClientOrganizationComponent } from './client/client-organization/client-organization.component';
import { ClientEntitiesComponent } from './client/client-organization/client-entities/client-entities.component';
import { ClientDepartmentsComponent } from './client/client-organization/client-departments/client-departments.component';
import { ClientProjectsComponent } from './client/client-organization/client-projects/client-projects.component';
import { UploadLocationsComponent } from './client/client-organization/upload-locations/upload-locations.component';

const monacoConfig: NgxMonacoEditorConfig = {
  defaultOptions: { theme: 'vs-dark', language: 'javascript', }, // pass default options to be used
  onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};
@NgModule({
  declarations: [
    ClientListComponent,
    Client3pdComponent,
    ClientConversionManagerComponent,
    ClientCategoryRateTypeMappingComponent,
    ClientBillingComponent,
    PosHouseAccountMappingsComponent,
    ClientUsersComponent,
    ClientLocationsComponent,
    ClientApiComponent,
    ClientSettingsComponent,
    ClientVendorMappingsComponent,
    ClientConversionComponent,
    ClientDayChargesComponent,
    ClientInvoicesComponent,
    ClientInvoiceCreditsComponent,
    TpdInitializeComponent,
    ClientOrganizationComponent,
    ClientEntitiesComponent,
    ClientDepartmentsComponent,
    ClientProjectsComponent,
    UploadLocationsComponent
  ],
  exports: [
    ClientListComponent,
    Client3pdComponent,
    ClientConversionManagerComponent,
    ClientCategoryRateTypeMappingComponent,
    PosHouseAccountMappingsComponent,
    ClientUsersComponent,
    ClientLocationsComponent,
    ClientApiComponent,
    ClientSettingsComponent,
    ClientConversionComponent,
    ClientDayChargesComponent,
    ClientInvoicesComponent,
    ClientInvoiceCreditsComponent,
    TpdInitializeComponent,
    ClientEntitiesComponent,
    ClientDepartmentsComponent,
    ClientProjectsComponent,
    UploadLocationsComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DsSharedModule,
    MonacoEditorModule.forRoot(monacoConfig),
  ]
})
export class ClientsModule {
}
