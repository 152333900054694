import {AdminLayoutComponent} from './views/admin-layout';
import {Routes} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {UnauthenticatedGuard} from "./guards/unauthenticated.guard";
import {LoginComponent} from "./auth/login/login.component";
import {DashboardComponent} from "./components/dashboard/dashboard/dashboard.component";
import {NotFoundComponent} from "./components/not-found/not-found.component";
import {ClientListComponent} from "./components/clients/client-list/client-list.component";
import {Client3pdComponent} from "./components/clients/client/client-3pd/client-3pd.component";
import {DataConverterComponent} from "./components/dev-tools/data-converter/data-converter.component";
import {ClientComponent} from "./views/client/client.component";
import {ClientBillingComponent} from "./components/clients/client/client-billing/client-billing.component";
import {ScripterComponent} from "./components/dev-tools/scripter/scripter.component";
import {SwaggerEditorComponent} from "./components/swagger-editor/swagger-editor.component";
import {ClientSettingsComponent} from "./components/clients/client/client-settings/client-settings.component";
import {ClientApiComponent} from "./components/clients/client/client-api/client-api.component";
import {ClientLocationsComponent} from "./components/clients/client/client-organization/client-locations/client-locations.component";
import {ClientUsersComponent} from "./components/clients/client/client-organization/client-users/client-users.component";
import {FireqlComponent} from "./components/dev-tools/fireql/fireql.component";
import { ContentEditorComponent } from './components/content-editor/content-editor.component';
import { ClientOrganizationComponent } from './components/clients/client/client-organization/client-organization.component';
import { UploadLocationsComponent } from './components/clients/client/client-organization/upload-locations/upload-locations.component';
import { TpdMappingsComponent } from './components/3pd/tpd-mappings/tpd-mappings.component';

export const ROUTES: Routes = [
  {path: '', redirectTo: 'login', pathMatch: 'full'},
  {path: 'login', component: LoginComponent, canActivate: [UnauthenticatedGuard]},
  {
    path: 'app', canActivate: [AuthGuard], component: AdminLayoutComponent, children: [
      {path: '', redirectTo: 'clients', pathMatch: 'full'},
      {path: 'tpd', children: [
        {path: 'tpd-mappings', component: TpdMappingsComponent}
      ]},
      {
        path: 'clients', children: [
          {path: '', redirectTo: 'list', pathMatch: 'full'},
          {path: 'list', component: ClientListComponent},
          {
            path: ':id', component: ClientComponent, children: [
              {path: '', redirectTo: 'organization', pathMatch: 'full'},
              {path: 'settings', component: ClientSettingsComponent},
              {path: 'users', component: ClientUsersComponent},
              {path: 'organization', component: ClientOrganizationComponent},
              {path: 'billing', component: ClientBillingComponent},
              {path: 'module-3pd', component: Client3pdComponent},
              {path: 'module-at', component: Client3pdComponent},
              {path: 'module-exc', component: Client3pdComponent},
              {path: 'api', component: ClientApiComponent},
            ]
          },
        ]
      },
      {
        path: 'tools', children: [
          {path: 'data-converter', component: DataConverterComponent},
          {path: 'fireql', component: FireqlComponent},
          {path: 'scripter', component: ScripterComponent}
        ]
      },
      {
        path: 'api-docs', component: SwaggerEditorComponent
      },
      {
        path: 'cms', component: ContentEditorComponent
      },
        {
        path: '**', component: NotFoundComponent
      }
    ]
  }
];
