import {Component, OnInit} from '@angular/core';
import {Papa} from "ngx-papaparse";
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-data-converter',
  templateUrl: './data-converter.component.html',
  styleUrls: ['./data-converter.component.scss']
})
export class DataConverterComponent implements OnInit {
  public convertedData = '';
  public selectedFile: any;
  originalDataInput = new FormControl('');
  private pastedData: any;

  constructor(private papa: Papa,
  ) {
  }

  ngOnInit() {
    this.originalDataInput.valueChanges.subscribe(value => {
      this.pastedData = value;
    })
  }
  download(text) {
    const element = document.createElement('a');
    // element.setAttribute('href', 'application/json' + text);
    const blob = new Blob([text], {type: "data:text/plain;charset=utf-8,"});
    const url  = URL.createObjectURL(blob);
    element.setAttribute('href', url);
    element.setAttribute('download', 'data.txt');
    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }
  convertCSVToJSON() {
    let dataToParse;
    if (this.selectedFile) {
      dataToParse = this.selectedFile;
    } else if (this.pastedData) {
      dataToParse = this.pastedData;
    }
    this.papa.parse(dataToParse, {
      header: true,
      worker: true,
      complete: (parsedData) => {
        this.convertedData = JSON.stringify(parsedData.data);
        this.download(this.convertedData);
      }
    });
  }

  detectFiles(event) {
    if (event.target.files && event.target.files[0]) {
      this.selectedFile = event.target.files[0];
    }
  };
}
