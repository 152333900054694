import {AfterViewChecked, Component, ElementRef, NgZone, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {FirebaseAuthService} from '../../auth/services/firebase-auth.service';
import {Router} from "@angular/router";
import {User} from "@deliver-sense-librarian/data-schema";
import {FirebaseApp} from "angularfire2";
import {MatDialog} from '@angular/material';
import {MalihuScrollbarService} from "ngx-malihu-scrollbar";
import {Subject, Subscription} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {scrollbarOptions} from 'app/shared/ds-constant';
import {PerfectScrollbarConfigInterface, PerfectScrollbarDirective} from "ngx-perfect-scrollbar";

const SMALL_WIDTH_BREAKPOINT = 960;

@Component({
    selector: 'app-layout-component',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})
export class AdminLayoutComponent implements OnInit, AfterViewChecked, OnDestroy {
    public user: User;
    numberUnreadNotifications = 0;
    scrollbarOptions = scrollbarOptions;
    public initialLogo: any;
    destroy$ = new Subject();
    private _router: Subscription;

    mediaMatcher: MediaQueryList = matchMedia(
        `(max-width: ${SMALL_WIDTH_BREAKPOINT}px)`
    );
    url: string;
    sidePanelOpened;
    options = {
        collapsed: false,
        compact: false,
        boxed: false,
        dark: false,
        dir: 'ltr'
    };
    @ViewChild('sidemenu', {'static': false})
    sidemenu;

    @ViewChild(PerfectScrollbarDirective, {'static': false})
    directiveScroll: PerfectScrollbarDirective;
    public config: PerfectScrollbarConfigInterface = {};

    constructor(public auth: FirebaseAuthService,
                private afs: AngularFirestore,
                private dialog: MatDialog,
                private firebaseApp: FirebaseApp,
                private router: Router,
                private _element: ElementRef,
                zone: NgZone,
                private mScrollbarService: MalihuScrollbarService) {
        this.mediaMatcher.addListener(mql =>
            zone.run(() => {
                // @ts-ignore
                this.mediaMatcher = mql;
            })
        );
    }

    ngOnInit() {
        this.url = this.router.url;
    }

    ngOnDestroy() {
        this.destroy$.next(true);
        this.destroy$.unsubscribe();
        // this._router.unsubscribe();
    }

    ngAfterViewChecked(): void {
        // this.mScrollbarService.initScrollbar('.mat-drawer-content', this.scrollbarOptions);
    }
    runOnRouteChange(): void {
        if (this.isOver()) {
            this.sidemenu.close();
        }

        this.updatePS();
    }
    showSupportDialog() {
    }

    private getUnreadNotifications() {
        this.afs.collection('userNotifications', ref => ref
            .where('toUser', '==', this.user.id)
            .where('read', '==', false))
            .snapshotChanges().pipe(takeUntil(this.destroy$))
            .subscribe((notifications$) => {
                this.numberUnreadNotifications = notifications$.length;
            })
    }

    gotToNotifications() {
        this.router.navigateByUrl('/admin/notifications');
    }

    showPrivacyPolicy() {
    }

    showTermsOfService() {
    }
    eceiveOptions($event): void {
        this.options = $event;
    }

    /**
     * From Primer
     */


    receiveOptions($event): void {
        this.options = $event;
    }
    isOver(): boolean {
        if (
            this.url === '/apps/messages' ||
            this.url === '/apps/calendar' ||
            this.url === '/apps/media' ||
            this.url === '/maps/leaflet' ||
            this.url === '/taskboard'
        ) {
            return true;
        } else {
            return this.mediaMatcher.matches;
        }
    }

    menuMouseOver(): void {
        if (this.mediaMatcher.matches && this.options.collapsed) {
            this.sidemenu.mode = 'over';
        }
    }

    menuMouseOut(): void {
        if (this.mediaMatcher.matches && this.options.collapsed) {
            this.sidemenu.mode = 'side';
        }
    }

    updatePS(): void {
        if (!this.mediaMatcher.matches && !this.options.compact) {
            setTimeout(() => {
                this.directiveScroll.update();
            }, 350);
        }
    }
}
