import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccordionDirective} from "./accordion.directive";
import {AccordionAnchorDirective} from "./accordionanchor.directive";
import {AccordionLinkDirective} from "./accordionlink.directive";
import {MaterialModule} from "../../../material-module.module";
import {FlexLayoutModule} from "@angular/flex-layout";

@NgModule({
    declarations: [AccordionDirective, AccordionAnchorDirective, AccordionLinkDirective],
    exports: [AccordionDirective, AccordionAnchorDirective, AccordionLinkDirective],
    imports: [
        MaterialModule,
        FlexLayoutModule,
        CommonModule
    ]
})
export class AccordionModule {
}
