import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SwaggerEditorComponent} from './swagger-editor.component';
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {FlexLayoutModule} from "@angular/flex-layout";
import {MaterialModule} from "../../material-module.module";

@NgModule({
  declarations: [SwaggerEditorComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    MaterialModule
  ]
})
export class SwaggerEditorModule {
}
