
export class FirestoreUtilities {
    public static mapToType(actions) {
        if (actions.length > 0) {
            return actions.map(a => {
                if (a) {
                    if (a.payload.doc) {
                        const data = a.payload.doc ? a.payload.doc.data() : a.payload.data();
                        const id = a.payload.doc ? a.payload.doc.id : a.payload.id;
                        return {id, ...data};
                    } else if (a.payload) {
                        const data = actions.payload.data();
                        const id = actions.payload.id;
                        return {id, ...data};
                    }
                }
            });
        } else {
            return [];
        }
    }

    public static objectToType(actions) {
        if (actions && actions.payload.exists) {
            const data = actions.payload.data();
            const id = actions.payload.id;
            return {id, ...data};
        } else {
            return null;
        }
    }

    public static mergeToType(actions) {
        const resultArray = [];
        if (actions instanceof Array) {
            actions.forEach(action => {
                resultArray.push(FirestoreUtilities.objectToType(action));
            })
        } else {
            resultArray.push(FirestoreUtilities.objectToType(actions));
        }
        return resultArray;
    }

    public static mergeCollectionToType(actions) {
        const resultArray = [];
        if (actions instanceof Array) {
            actions.forEach(action => {
                if (action instanceof Array) {
                    action.forEach(item => {
                        if (item) {
                            const data = item.payload.doc ? item.payload.doc.data() : item.payload.data();
                            const id = item.payload.doc ? item.payload.doc.id : item.payload.id;
                            resultArray.push({id, ...data});
                        }
                    });
                } else if (action && action.payload) {

                }
            })
        }
        return resultArray;
    }
}
