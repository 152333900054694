import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {scrollbarOptions} from "../../shared/ds-constant";

@Component({
  selector: 'app-tpd-conversion-dialog',
  templateUrl: './tpd-conversion-dialog.component.html',
  styleUrls: ['./tpd-conversion-dialog.component.scss']
})
export class TpdConversionDialogComponent implements OnInit {
  scrollbarOptions = scrollbarOptions;

  constructor(public dialogRef: MatDialogRef<TpdConversionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
