import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FirebaseAuthService } from "../../../../../auth/services/firebase-auth.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import {
  Client, ClientPosTransactionConversion, ClientPosTransactionItemConversion, ClientThirdPartyTransactionConversion,
  DataConverter,
  PosSystem,
  ThirdParty,
} from "@deliver-sense-librarian/data-schema";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Subject } from "rxjs";
import { takeUntil, first } from 'rxjs/operators';
import { FirestoreUtilities } from "../../../../../utilities/firestore-utilities";
import { FormBuilder } from "@angular/forms";
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ConfirmDialogComponent } from "../../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { Papa } from "ngx-papaparse";
import * as firebase from "firebase";
import { ClientConversionDialogComponent } from "../../../../../dialogs/clients/client-conversion-dialog/client-conversion-dialog.component";
import { Conversion } from "@deliver-sense-librarian/data-schema/dist/models/interfaces";

@Component({
  selector: 'app-client-conversion-manager',
  templateUrl: './client-conversion-manager.component.html',
  styleUrls: ['./client-conversion-manager.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class ClientConversionManagerComponent implements OnInit {
  @Input() client: Client;
  @Input() thirdParty: ThirdParty;
  @Input() posSystem: PosSystem;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['keyOut', 'keyIn', 'method', 'params', 'edit', 'expandTrigger'];
  public expandedElement: any | null;
  public parents: ThirdParty[] | PosSystem[] = [];
  public fullConversionResults: any[];
  public showFullTest: boolean;
  private conversionType: 'clientPosTransactionConversions' | 'clientThirdPartyTransactionConversions' | 'clientPosTransactionItemConversions';
  private _destroy$ = new Subject();
  private conversions: Conversion[] = [];
  private _dataConverter = new DataConverter();
  private currentOutput: string | 'invalid rule';

  constructor(private auth: FirebaseAuthService,
    private papa: Papa,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    if (this.thirdParty) {
      this.conversionType = 'clientThirdPartyTransactionConversions'
    } else {
      this.conversionType = 'clientPosTransactionConversions';
    }
    this._getClientConversionMappings();
  }

  editConversion(conversion) {
    this.dialog.open(ClientConversionDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        client: this.client,
        conversion,
        conversionType: this.conversionType,
        conversionTypeName: this.getConversionTypeName(),
      }
    })
  }

  createConversion() {
    this.dialog.open(ClientConversionDialogComponent, {
      data: {
        client: this.client,
        conversion: this.getNewOfType(),
        conversionType: this.conversionType,
        conversionTypeName: this.getConversionTypeName(),
      }
    })
  }

  private getNewOfType() {
    switch (this.conversionType) {
      case "clientPosTransactionConversions":
        return new ClientPosTransactionConversion();
      case "clientPosTransactionItemConversions":
        return new ClientPosTransactionItemConversion();
      case "clientThirdPartyTransactionConversions":
        return new ClientThirdPartyTransactionConversion();
    }
  }

  private _getClientConversionMappings() {
    let query: firebase.firestore.Query = this.afs.collection(this.conversionType).ref;
    query = query.where('client', '==', this.client.id);
    if (this.conversionType === 'clientThirdPartyTransactionConversions') {
      query = query.where('thirdParty', '==', this.thirdParty.id);
    } else if (this.conversionType === 'clientPosTransactionConversions') {
      query = query.where('posSystem', '==', this.posSystem.id);
    }
    this.afs.collection(this.conversionType, ref => query)
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(clientConverstions$ => {
        this.conversions = FirestoreUtilities.mapToType(clientConverstions$);
        this.tableData = new MatTableDataSource(this.conversions);
      })
  }

  convertCSVToJson(csv): Promise<any[]> {
    return new Promise(resolve => {
      this.papa.parse(csv, {
        header: true,
        worker: true,
        complete: (result) => {
          resolve(result.data);
        }
      });
    });
  }

  async getConversion(csv: any, conversion: Conversion) {
    const jsonValue = await this.convertCSVToJson(csv);
    const values = jsonValue ? jsonValue[0] : {};
    if (conversion.algorithm[0].method === 'code') {
      this.currentOutput = this._dataConverter.applyAlgorithm(jsonValue[0], conversion.algorithm[0].code);
    } else {
      const fields = conversion.algorithm[0].fields.map(fieldName => {
        const fieldValue = values[fieldName];
        return fieldValue;
      });
      const conversionResult = this._dataConverter.convertData(fields, conversion.algorithm[0].method, conversion.algorithm[0].methodParameters);
      this.currentOutput = conversionResult ? conversionResult : conversion.algorithm[0].default;
    }
  }

  async testFullConversion(csv: string) {
    const jsonValue = await this.convertCSVToJson(csv);
    const result = [];
    let accountConversions;
    if (this.conversionType === 'clientPosTransactionConversions') {
      accountConversions = await this.afs.collection('clientPosTransactionAccountConversions', ref => ref
        .where('client', '==', this.client.id)
        .where('posSystem', '==', this.posSystem.id))
        .valueChanges()
        .pipe(first()).toPromise();
    }
    jsonValue.forEach(originalObject => {
      const convertedObject = {};
      this.conversions.forEach(conversion => {
        if (conversion.algorithm && conversion.algorithm[0].method === 'code') {
          convertedObject[conversion.keyOut] = this._dataConverter.applyAlgorithm(originalObject, conversion.algorithm[0].code);
        } else if (conversion.algorithm && conversion.algorithm[0].method && conversion.algorithm[0].fields) {
          const fieldValues = conversion.algorithm[0].fields.map(fieldName => {
            const fieldValue = originalObject[fieldName];
            return fieldValue;
          });
          if (fieldValues && fieldValues[0]) {
            const convertedValue = this._dataConverter.convertData(fieldValues, conversion.algorithm[0].method, conversion.algorithm[0].methodParameters);
            convertedObject[conversion.keyOut] = convertedValue !== undefined || convertedValue !== null || !isNaN(convertedValue)
              ? convertedValue :
              conversion.algorithm[0].default ?
                conversion.algorithm[0].default :
                'Conversion Error'
          } else {
            convertedObject[conversion.keyOut] = originalObject[conversion.keyIn];
          }
        } else if (conversion.keyOut === 'account') {
          const matchingAccount = accountConversions.find(accountConversion => accountConversion.keyIn === originalObject[conversion.keyIn]);
          if (matchingAccount) {
            convertedObject[conversion.keyOut] = matchingAccount.account;
          } else {
            convertedObject[conversion.keyOut] = 'No Matching Account Conversion';
          }
        } else {
          convertedObject[conversion.keyOut] = originalObject[conversion.keyIn];
        }
      });
      result.push(convertedObject);
    });
    this.fullConversionResults = result;
  }

  getConversionTypeName() {
    switch (this.conversionType) {
      case "clientPosTransactionConversions":
      case "clientPosTransactionItemConversions":
        return "Pos System";
      case "clientThirdPartyTransactionConversions":
        return 'Third Party';
    }
  }

  async delete(conversion) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this mapping?',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`${this.conversionType}/${conversion.id}`).delete();
        this.snackBar.open('Deletion successful', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }


  getConversionValues(row: any) {
    if (row.algorithm && row.algorithm[0].fields && row.algorithm[0].fields.length > 0) {
      return row.algorithm[0].fields.reduce((result, curr) => {
        return result + curr + ', ';
      }, '');
    } else {
      return row.keyIn;
    }
  }

  getMethodParams(row: any) {
    if (row.algorithm && row.algorithm[0].methodParameters && row.algorithm[0].methodParameters.length > 0) {
      return row.algorithm[0].methodParameters.reduce((result, curr) => {
        return result + curr + ', ';
      }, '');
    } else {
      return '';
    }
  }
}
