import { Component, OnInit, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Client, ClientDayCharge, DeliverSenseModule, ClientModule } from '@deliver-sense-librarian/data-schema';
import { FormControl, Validators } from '@angular/forms';
import { Papa } from 'ngx-papaparse';
import { FirebaseApp } from 'angularfire2';
import { MatDialog, MatSnackBar, MatPaginator, MatSort, MatTableDataSource } from '@angular/material';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import { from, Subject } from 'rxjs';
import { combineAll, takeUntil, map } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { AngularFirestore } from '@angular/fire/firestore';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ReasonPromptDialogComponent } from '../../../../../dialogs/reason-prompt-dialog/reason-prompt-dialog.component';
import * as _ from 'lodash';

@Component({
  selector: 'app-client-day-charges',
  templateUrl: './client-day-charges.component.html',
  styleUrls: ['./client-day-charges.component.scss']
})
export class ClientDayChargesComponent implements OnInit {
  @Input() client: Client;
  @Input() dsModules: DeliverSenseModule[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['id', 'chargeDate', 'module', 'subTotal', 'unit', 'unitQuantity', 'edit'];
  public startDate = new FormControl(null, Validators.required);
  public endDate = new FormControl(null, [Validators.required]);
  public dayCharges: ClientDayCharge[] = [];
  public editDayCharge: ClientDayCharge = null;
  private _destroy$ = new Subject();
  constructor(private store: Store<any>,
    private papa: Papa,
    private firebaseApp: FirebaseApp,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }
  runSearch() {
    this.dayCharges = null;
    const start = moment(this.startDate.value).startOf('day').toDate();
    const end = moment(this.endDate.value).endOf('day').toDate();
    this.afs.collection('clientDayCharges', ref => ref
      .where('client', '==', this.client.id)
      .where('chargeDate', '>=', start)
      .where('chargeDate', '<=', end)
      .orderBy('chargeDate', 'desc')
    )
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(dayCharges$ => {
        this.dayCharges = FirestoreUtilities.mapToType(dayCharges$);
        const uniqueClientModuleIds = _.uniqBy(this.dayCharges, 'clientModule').map(dayCharge => dayCharge.clientModule);
        const clientModuleRequests = uniqueClientModuleIds.map(clientModuleId => {
          return this.afs.doc(`clientModules/${clientModuleId}`).snapshotChanges();
        });
        from(clientModuleRequests)
          .pipe(combineAll(), takeUntil(this._destroy$))
          .subscribe((clientModules$) => {
            const clientModules = FirestoreUtilities.mergeToType(clientModules$);
            this.dayCharges.forEach(dayCharge => {
              const dayChargeClientModule = clientModules.find(clientModule => clientModule.id === dayCharge.clientModule);
              if (dayChargeClientModule) {
                dayCharge.clientModule = dayChargeClientModule;
              }
            })
            this.tableData = new MatTableDataSource(this.dayCharges);
            this.tableData.paginator = this.paginator;
            this.tableData.sort = this.sort;
            this._cdr.detectChanges();
          })
      });
  }
  getDeliverSenseModule(clientModule: ClientModule) {
    if (clientModule) {
      const module = this.dsModules.find(dsModule => dsModule.id === clientModule.module);
      return module ? module.name : '';
    }
    return '';
  }
  getMaxEndDate() {
    if (this.startDate.valid) {
      return moment(this.startDate.value).add(3, 'months').toDate();
    }
  }
  setEditDayCharge(dayCharge: ClientDayCharge) {
    this.editDayCharge = Object.assign(new ClientDayCharge(), dayCharge);
  }
  saveEditDayCharge() {
    const reasonDialog = this.dialog.open(ReasonPromptDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        title: 'Reason for day charge change',
        placeholder: 'Reason'
      }
    });
    reasonDialog.afterClosed().subscribe(async (reason) => {
      const history = this.editDayCharge['history'] ? this.editDayCharge['history'] : [];
      const originalCharge = this.dayCharges.find(charge => charge.id === this.editDayCharge.id);
      const newHistoryItem = {
        from: {
          subTotal: originalCharge.subTotal,
          unitQuantity: originalCharge.unitQuantity
        },
        to: {
          subTotal: this.editDayCharge.subTotal,
          unitQuantity: this.editDayCharge.unitQuantity
        },
        reason: reason
      }
      history.push(newHistoryItem);
      await this.afs.doc(`dayCharges/${this.editDayCharge.id}`).update({
        history: history,
        subTotal: this.editDayCharge.subTotal,
        unitQuantity: this.editDayCharge.unitQuantity,
      });
      this.snackBar.open('Day charge updated', 'Dismiss', {
        duration: 5000
      });
      this.editDayCharge = null;
    })
  }
}
