import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TpdMappingsComponent } from './tpd-mappings/tpd-mappings.component';
import { TpdMappingsTableComponent } from './tpd-mappings-table/tpd-mappings-table.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MaterialModule } from 'app/material-module.module';
import { DsSharedModule } from 'app/shared/ds-shared.module';
import { TpdConversionComponent } from './tpd-conversion/tpd-conversion.component';
import { NgxMonacoEditorConfig, MonacoEditorModule } from 'ngx-monaco-editor';
import { TpdStatusesComponent } from './tpd-statuses/tpd-statuses.component';
const monacoConfig: NgxMonacoEditorConfig = {
  defaultOptions: { theme: 'vs-dark', language: 'javascript', }, // pass default options to be used
  onMonacoLoad: () => { console.log((<any>window).monaco); } // here monaco object will be available as window.monaco use this function to extend monaco editor functionalities.
};
@NgModule({
  declarations: [TpdMappingsComponent, TpdMappingsTableComponent, TpdConversionComponent, TpdStatusesComponent],
  exports: [TpdMappingsTableComponent, TpdConversionComponent, TpdStatusesComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DsSharedModule,
    MonacoEditorModule.forRoot(monacoConfig),
  ]
})
export class TpdModule { }
