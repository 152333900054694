import {Component} from '@angular/core';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  menuitems = [
    {
      state: 'clients',
      name: 'CLIENTS',
      type: 'link',
      icon: 'business'
    },
    {
      state: 'tools',
      name: 'Tools',
      type: 'sub',
      icon: 'building',
      children: [
        {state: 'data-converter', icon: 'data', name: 'DATA CONVERTER'},
        {state: 'scripter', icon: 'script', name: 'SCRIPT RUNNER'},
        {state: 'fireql', icon: 'query_builder', name: 'FireQl'}
      ]
    },
    {
      state: 'api-docs',
      name: 'API DOCS',
      type: 'link',
      icon: 'book'
    },
    {
      state: 'cms',
      name: 'CMS',
      type: 'link',
      icon: 'edit'
    },
    {
      state: 'tpd',
      name: '3PD',
      type: 'sub',
      icon: 'location',
      children: [
        {state: 'tpd-mappings', icon: 'map', name: '3PD MAPPINGS'}
      ]
    }
  ];

  constructor() {
  }
}
