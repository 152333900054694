import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AccessControlService} from "./access-control.service";
import {StripeService} from "./stripe.service";
import {FunctionsService} from "./functions.service";
import {LoadingDialogService} from "./loading-dialog.service";
import {PushMessagingService} from "./push-messaging.service";
import {HelpService} from "./help.service";

@NgModule({
    imports: [
        CommonModule
    ],
    declarations: [],
    providers: [
        AccessControlService,
        StripeService,
        FunctionsService,
        PushMessagingService,
        LoadingDialogService,
        HelpService
    ]
})
export class ServicesModule {}
