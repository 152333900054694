import {AfterViewInit, Component, OnInit} from '@angular/core';


declare const jquery: any;
declare const $: any;


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit, AfterViewInit {


    constructor() {
    }

    ngOnInit() {
        this.removePreloader();
    }

    ngAfterViewInit(): void {
    }

    removePreloader() {
        setTimeout(() => {
            $('.preloader').fadeOut(1000); // set duration in brackets
            $("html").css({
                "touch-action": "pan-down"
            });
            $("html, body").animate({scrollTop: $(document).height()}, 1000);

        }, 2000)
    }
}
