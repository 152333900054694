import {Client} from "@deliver-sense-librarian/data-schema";

export interface UiState {
    currentClient: Client;
    currentError?: string;
    authUser: any;
}

export const INITIAL_UI_STATE: UiState = {
  authUser: JSON.parse(localStorage.getItem('user')),
    currentClient: undefined,
    currentError: undefined,
};
