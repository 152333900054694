import {Component, Inject, OnInit} from '@angular/core';
import {AngularFirestore} from "@angular/fire/firestore";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {scrollbarOptions} from "../../../shared/ds-constant";

@Component({
  selector: 'app-client-conversion-dialog',
  templateUrl: './client-conversion-dialog.component.html',
  styleUrls: ['./client-conversion-dialog.component.scss']
})
export class ClientConversionDialogComponent implements OnInit {
  scrollbarOptions = scrollbarOptions;

  constructor(public dialogRef: MatDialogRef<ClientConversionDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: any) { }

  ngOnInit() {
  }

}
