import { MaterialModule } from '../material-module.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MalihuScrollbarModule } from "ngx-malihu-scrollbar";
import { ReasonPromptDialogComponent } from "./reason-prompt-dialog/reason-prompt-dialog.component";
import { ConfirmLeaveDialogComponent } from './unsaved-changes/confirm-leave-dialog.component';
import { DsSharedModule } from "../shared/ds-shared.module";
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from "ng-recaptcha";
import { ConfirmDialogComponent } from "./confirm-dialog/confirm-dialog.component";
import { LoadingDialogComponent } from "./loading-dialog/loading-dialog.component";
import { ClientConversionDialogComponent } from './clients/client-conversion-dialog/client-conversion-dialog.component';
import { ClientsModule } from "../components/clients/clients.module";
import { ClientModuleBillingRateDialogComponent } from './client-module-billing-rate-dialog/client-module-billing-rate-dialog.component';
import { LocationUploadDialogComponent } from './location-upload-dialog/location-upload-dialog.component';
import { TpdConversionDialogComponent } from './tpd-conversion-dialog/tpd-conversion-dialog.component';
import { TpdModule } from '../components/3pd/tpd.module';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    DsSharedModule,
    FlexLayoutModule,
    RecaptchaModule.forRoot(), // Keep in mind the "forRoot"-magic nuances!
    RecaptchaFormsModule,
    MalihuScrollbarModule.forRoot(),
    ClientsModule,
    TpdModule
  ],
  declarations: [
    ReasonPromptDialogComponent,
    ConfirmLeaveDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    ClientConversionDialogComponent,
    ClientModuleBillingRateDialogComponent,
    LocationUploadDialogComponent,
    TpdConversionDialogComponent
  ],
  entryComponents: [
    ReasonPromptDialogComponent,
    ConfirmLeaveDialogComponent,
    ConfirmDialogComponent,
    LoadingDialogComponent,
    ClientConversionDialogComponent,
    ClientModuleBillingRateDialogComponent,
    LocationUploadDialogComponent,
    TpdConversionDialogComponent
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: '6LcfNbkUAAAAAEz20Rw2LMS8NAjPXA9eG-SZ89DF',
      } as RecaptchaSettings,
    },
  ]

})
export class DialogsModule {
}
