import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Client, ThirdParty, ClientPosTransactionAccountConversion, PosSystem } from "@deliver-sense-librarian/data-schema";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { FirebaseAuthService } from "../../../../../auth/services/firebase-auth.service";
import { Papa } from "ngx-papaparse";
import { MatDialog } from "@angular/material/dialog";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { takeUntil } from "rxjs/operators";
import { FirestoreUtilities } from "../../../../../utilities/firestore-utilities";
import { BehaviorSubject, combineLatest, Subject } from "rxjs";
import { ConfirmDialogComponent } from "../../../../../dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-pos-house-account-mappings',
  templateUrl: './pos-house-account-mappings.component.html',
  styleUrls: ['./pos-house-account-mappings.component.scss']
})
export class PosHouseAccountMappingsComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public selectedPos: PosSystem = null;
  public selectPosControl = new FormControl();
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['account', 'posSystem', 'keyIn', 'edit'];
  public posSystems: PosSystem[] = [];
  public accounts: { id, name }[] = [];
  public conversionInEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public conversionForm: FormGroup;
  public posAccountMappings = [];
  private _destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
    private papa: Papa,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.getRelatedResources();
    this.selectPosControl.valueChanges.subscribe(selectedPos =>{
      this.selectedPos = null;
      setTimeout(() => {
        this.selectedPos = selectedPos;
        if (this.selectedPos && this.selectedPos.id) {
          this.getPosAccountMappings();
        }
      })
    })
    // this.getPosAccountMappings();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  public setMappingInEdit(mapping?: any) {
    if (mapping) {
      this.conversionInEdit.next(mapping);
    } else {
      this.conversionInEdit.next(new ClientPosTransactionAccountConversion({posSystem: this.selectedPos.id}))
    }
    this.setupMappingForm();
  }

  private setupMappingForm() {
    this.conversionInEdit.subscribe((conversion$: any) => {
      if (conversion$) {
        this.conversionForm = this.fb.group({
          account: new FormControl(conversion$.account, Validators.required),
          keyIn: new FormControl(conversion$.keyIn, Validators.required),
          posSystem: new FormControl(conversion$.posSystem, Validators.required),
        });
      }
    })
  }

  async save() {
    if (this.conversionForm.valid) {
      const conversionFormValues = this.conversionForm.value;
      // if (!this.noExistingMapping(conversionFormValues)) {
        const conversion = {
          account: conversionFormValues.account,
          posSystem: conversionFormValues.posSystem,
          keyIn: conversionFormValues.keyIn,
          client: this.client.id
        };
        if (this.conversionInEdit.value.id) {
          await this.afs.doc(`clientPosTransactionAccountConversions/${this.conversionInEdit.value.id}`).update(
            conversion
          )
        } else {
          await this.afs.collection(`clientPosTransactionAccountConversions`).add(
            conversion
          )
        }
        this.snackBar.open(`${this.getPosName(conversion.posSystem)} House Account ${this.conversionInEdit.value.id ? 'Updated' : 'Created'}`, 'Dismiss', {
          duration: 5000
        });
        this.conversionForm = null;
        this.conversionInEdit.next(null);
      // } else {
      //   this.snackBar.open('You already have a mapping for this POS and House Account type.', 'Dismiss', {
      //     duration: 5000
      //   });
      // }
    } else {
      this.snackBar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      });
    }
  }
  noExistingMapping(formValues) {
    return !!this.posAccountMappings.find((mapping: ClientPosTransactionAccountConversion) => mapping.account === formValues.account && mapping.posSystem === formValues.posSystem)

  }
  getThirdPartyName(thirdPartyId: string) {
    if (thirdPartyId === 'ex') {
      return 'Tax Exempt Transactions';
    }
    const thirdParty = this.accounts.find(_thirdParty => _thirdParty.id === thirdPartyId)
    return thirdParty ? thirdParty.name : '';
  }

  getPosName(posId: string) {
    const pos = this.posSystems.find(_pos => _pos.id === posId)
    return pos ? pos.name : '';
  }

  private getRelatedResources() {
    combineLatest([
      this.afs.collection('posSystems')
        .snapshotChanges(),
      this.afs.collection('thirdParties')
        .snapshotChanges(),
    ]).pipe(takeUntil(this._destroy$))
      .subscribe(([pos$, thirdParties$]) => {
        this.posSystems = FirestoreUtilities.mapToType(pos$);
        this.accounts = FirestoreUtilities.mapToType(thirdParties$);
        this.accounts.push({ id: 'ex', name: 'Tax Exempt Transactions' });
      })
  }

  public cancel() {
    this.conversionForm = null;
    this.conversionInEdit.next(null);
  }

  async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this house account mapping?',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`clientPosTransactionAccountConversions/${this.conversionInEdit.value.id}`).delete();
        this.conversionInEdit.next(null);
        this.snackBar.open('Deletion successful', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }

  private getPosAccountMappings() {
    this.afs.collection('clientPosTransactionAccountConversions', ref => ref
      .where('client', '==', this.client.id)
      .where('posSystem','==', this.selectedPos.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(clientPosTransactionAccountConversions$ => {
        this.posAccountMappings = FirestoreUtilities.mapToType(clientPosTransactionAccountConversions$);
        this.tableData = new MatTableDataSource(this.posAccountMappings);
      });
  }
}
