import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-query-results',
  templateUrl: './query-results.component.html',
  styleUrls: ['./query-results.component.scss']
})
export class QueryResultsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
