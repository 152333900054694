import {Component, OnInit, AfterViewInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

import {ActivatedRoute, Router} from '@angular/router';
import {FirebaseAuthService} from "../services/firebase-auth.service";
import {MatSnackBar} from "@angular/material";

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, AfterViewInit {

    loginForm: FormGroup;
    submitted = false;
    returnUrl: string;
    error = '';
    loading = false;

    constructor(private fb: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                private snackBar: MatSnackBar,
                private fbAuth: FirebaseAuthService) {
    }

    ngOnInit() {
        this.loginForm = this.fb.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', Validators.required],
        });

        // reset login status
        this.fbAuth.signOut();

        // get return url from route parameters or default to '/'
        // tslint:disable-next-line: no-string-literal
        this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';
    }

    ngAfterViewInit() {
        document.body.classList.add('authentication-bg');
    }

    // convenience getter for easy access to form fields
    get f() {
        return this.loginForm.controls;
    }
    /**
     * On submit form
     */
    async onSubmit() {
      if (!this.submitted) {
        this.submitted = true;
        if (this.loginForm.invalid) {
          return;
        }
        // this.loadingService.isLoading(true);
        try {
          await this.fbAuth.login(this.loginForm.value.email, this.loginForm.value.password);
          await this.router.navigate(['/app']);
        } catch (e) {
          this.submitted = false;
          this.snackBar.open('Invalid email and/or password', 'Dismiss', {
            duration: 5000
          });
        }
      }
    }
}
