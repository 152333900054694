import { MatSnackBar } from '@angular/material';
import { Component, Inject, OnInit } from '@angular/core';
import { AngularFirestore } from "@angular/fire/firestore";
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { ClientModuleBillingRate, Client, ClientModule } from '@deliver-sense-librarian/data-schema';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-client-module-billing-rate-dialog',
  templateUrl: './client-module-billing-rate-dialog.component.html',
  styleUrls: ['./client-module-billing-rate-dialog.component.scss']
})
export class ClientModuleBillingRateDialogComponent implements OnInit {
  clientModuleBillingRate: ClientModuleBillingRate;
  clientModule: ClientModule;
  client: Client;
  public availableUnits = [
    'locations',
    'users',
    'storage'
  ]
  billingRateForm: FormGroup;
  constructor(public dialogRef: MatDialogRef<ClientModuleBillingRateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { client, clientModuleBillingRate, clientModule},
    private afs: AngularFirestore,
    private snackbar: MatSnackBar,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router) {
    this.clientModuleBillingRate = data.clientModuleBillingRate ? data.clientModuleBillingRate : new ClientModuleBillingRate();
    this.client = data.client;
    this.clientModule = data.clientModule;
  }

  ngOnInit() {
    this.billingRateForm = this.fb.group({
      unitMonthlyRate: new FormControl(this.clientModuleBillingRate.unitMonthlyRate, Validators.required),
      unit: new FormControl(this.clientModuleBillingRate.unit, Validators.required),
    });
  }

  async save() {
    if (this.billingRateForm.valid) {
      const formValues = this.billingRateForm.value;
      if (this.clientModuleBillingRate.id) {
        await this.afs.doc(`clientModuleBillingRates/${this.clientModuleBillingRate.id}`).update({
          unitMonthlyRate: formValues.unitMonthlyRate,
          unit: formValues.unit,
        });
      } else {
        await this.afs.collection(`clientModuleBillingRates`).add({
          unitMonthlyRate: formValues.unitMonthlyRate,
          unit: formValues.unit,
          clientModule: this.clientModule.id
        });
      }
      this.snackbar.open('Billing rate saved', 'Dismiss', {
        duration: 5000
      });
      this.dialogRef.close();
    } else {
      this.snackbar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      })
    }
  }
  cancel() {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent,
      {
        data: {
          title: 'Confirm Leave',
          message: 'Are you sure you want to leave and lose unsaved changes?',
          action: 'Yes, leave'
        }
      });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.dialogRef.close();
      }
    })
  }
}
