import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormArray, FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {
  ClientPosTransactionConversion,
  ClientPosTransactionItemConversion,
  ClientThirdPartyTransactionConversion,
  dataConverterParameters,
  PosSystem,
  ThirdParty,
  Client, thirdPartyTransactionFields, posTransactionFields, posTransactionItemFields
} from "@deliver-sense-librarian/data-schema";
import * as _ from "lodash";
import {ConfirmDialogComponent} from "../../../../../dialogs/confirm-dialog/confirm-dialog.component";
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {AngularFirestore} from "@angular/fire/firestore";
import {ConversionFunction} from "@deliver-sense-librarian/data-schema/dist/models/classes/conversion-function";
import {takeUntil} from "rxjs/operators";
import {Subject} from "rxjs";
import {FirestoreUtilities} from "../../../../../utilities/firestore-utilities";

@Component({
  selector: 'app-client-conversion',
  templateUrl: './client-conversion.component.html',
  styleUrls: ['./client-conversion.component.scss']
})
export class ClientConversionComponent implements OnInit {
  @Input() client: Client;
  @Input() conversion: ClientPosTransactionConversion | ClientThirdPartyTransactionConversion;
  @Input() conversionType: 'clientPosTransactionConversions' | 'clientThirdPartyTransactionConversions' | 'clientPosTransactionItemConversions';
  @Input() conversionTypeName: 'Pos System' | 'Third Party';
  @Input() parents: ThirdParty[] | PosSystem[] = [];
  @Output() complete = new EventEmitter();
  conversionParent: PosSystem | ThirdParty;
  public availableFields = thirdPartyTransactionFields;
  public conversionForm: FormGroup;
  public conversionMethods = Object.keys(dataConverterParameters);
  public parameterOptions = [];
  private availableParameters: string[];
  editorOptions = {theme: 'vs-dark', language: 'javascript', scrollBeyondLastLine: false};
  public conversionAlgorithmCode = `/****
                                    * use row['fieldName'] to access the values of the incoming object
                                    *****/`;
  private _destroy$ = new Subject();

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.setFieldsOfType();
    this.setConversionParent();
    this.setupMappingForm();
  }

  private setFieldsOfType() {
    switch (this.conversionType) {
      case "clientPosTransactionConversions":
        this.availableFields = posTransactionFields;
        break;
      case "clientPosTransactionItemConversions":
        this.availableFields = posTransactionItemFields;
        break;
      case "clientThirdPartyTransactionConversions":
        this.availableFields = thirdPartyTransactionFields;
        break;
    }
  }

  private setupMappingForm() {
    this.conversionForm = this.fb.group({
      parent: new FormControl(this.conversionParent, Validators.required),
      keyIn: new FormControl(this.conversion.keyIn, Validators.required),
      keyOut: new FormControl(this.conversion.keyOut, Validators.required),
      default: new FormControl(this.conversion.algorithm && this.conversion.algorithm[0].default ? this.conversion.algorithm[0].default : ''),
      method: new FormControl(this.conversion.algorithm && this.conversion.algorithm[0].method ? this.conversion.algorithm[0].method : ''),
      code: new FormControl(this.conversion.algorithm && this.conversion.algorithm[0].code ? this.conversion.algorithm[0].code :
        `/**
        * Access row properties via row['fieldName']
        **/
      `),
      methodParameters: new FormArray([]),
      fields: new FormArray([])
    });
    this.setupFormListeners();
    this.conversionForm.get('method').updateValueAndValidity();
    this.populateFormArrays(this.conversion);
  }

  public getParameterName(index) {
    const methodParams = dataConverterParameters[this.conversionForm.get('method').value];
    if (methodParams) {
      return Object.keys(methodParams)[index];
    }
    return '';
  }


  private setupFormListeners() {
    this.conversionForm.get('method').valueChanges.subscribe(method$ => {
      const methodParams = dataConverterParameters[method$];
      if (methodParams) {
        this.availableParameters = Object.keys(methodParams);
      }
    });
  }

  public addFormArrayGroup(formArrayName: 'methodParameters' | 'fields') {
    const methodParameters = this.conversionForm.get(formArrayName) as FormArray;
    methodParameters.push(new FormGroup({
      value: new FormControl()
    }));
  }

  public removeFormArrayGroup(formArrayName: 'methodParameters' | 'fields', index) {
    const methodParameters = this.conversionForm.get(formArrayName) as FormArray;
    methodParameters.removeAt(index);
  }

  private getNewOfType() {
    switch (this.conversionType) {
      case "clientPosTransactionConversions":
        return new ClientPosTransactionConversion();
      case "clientPosTransactionItemConversions":
        return new ClientPosTransactionItemConversion();
      case "clientThirdPartyTransactionConversions":
        return new ClientThirdPartyTransactionConversion();
    }
  }

  private mapFormToConversion(formValues) {
    const newConversion = this.getNewOfType();
    const newAlgorithm = new ConversionFunction();
    newConversion.keyOut = formValues.keyOut;
    newConversion.keyIn = formValues.keyIn;
    if (formValues.method) {
      newConversion.algorithm = [newAlgorithm];
      if (formValues.method === 'code') {
        newConversion.algorithm[0].code = formValues.code;
      }
      newConversion.algorithm[0].method = formValues.method;
      newConversion.algorithm[0].methodParameters = formValues.methodParameters ? _.map(formValues.methodParameters, 'value') : [];
      newConversion.algorithm[0].fields = formValues.fields ? _.map(formValues.fields, 'value') : [];
      newConversion.algorithm[0].default = formValues.default;
      newConversion.algorithm[0] = newConversion.algorithm[0].toJSONObject();
    } else {
      newConversion.algorithm = null;
    }
    this._setConversionParent(newConversion, formValues);
    newConversion.client = this.client.id;
    return newConversion;
  }

  private _setConversionParent(newConversion, formValues: any) {
    switch (this.conversionType) {
      case "clientPosTransactionConversions":
      case "clientPosTransactionItemConversions":
        newConversion.posSystem = formValues.parent;
        break;
      case "clientThirdPartyTransactionConversions":
        newConversion.thirdParty = formValues.parent;
        break;
    }
  }

  private setConversionParent() {
    switch (this.conversionType) {
      case "clientPosTransactionConversions":
      case "clientPosTransactionItemConversions":
        this._getAvailablePos();
        this.conversionParent = this.conversion['posSystem'];
        break;
      case "clientThirdPartyTransactionConversions":
        this._getAvailableThirdParties();
        this.conversionParent = this.conversion['thirdParty'];
        break;
    }
  }

  private populateFormArrays(conversion) {
    if (conversion.algorithm && conversion.algorithm[0]) {
      if (conversion.algorithm[0].methodParameters) {
        conversion.algorithm[0].methodParameters.forEach(methodParameter => {
          const methodParameters = this.conversionForm.get('methodParameters') as FormArray;
          methodParameters.push(new FormGroup({
            value: new FormControl(methodParameter)
          }));
        });
      } else {
        const methodParameters = this.conversionForm.get('methodParameters') as FormArray;
        methodParameters.push(new FormGroup({
          value: new FormControl('')
        }));
      }
      if (conversion.algorithm[0].fields) {
        conversion.algorithm[0].fields.forEach(field => {
          const fields = this.conversionForm.get('fields') as FormArray;
          fields.push(new FormGroup({
            value: new FormControl(field)
          }));
        });
      } else {
        const fields = this.conversionForm.get('fields') as FormArray;
        fields.push(new FormGroup({
          value: new FormControl('')
        }));
      }
    }
  }

  async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this mapping?',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`${this.conversionType}/${this.conversion.id}`).delete();
        this.snackBar.open('Deletion successful', 'Dismiss', {
          duration: 5000
        })
      }
      this.complete.emit(true);
    });
  }

  async save() {
    if (this.conversionForm.valid) {
      const conversion = this.mapFormToConversion(this.conversionForm.value);
      if (this.conversion.id) {
        await this.afs.doc(`${this.conversionType}/${this.conversion.id}`).update(
          conversion.toJSONObject()
        )
      } else {
        await this.afs.collection(`${this.conversionType}`).add(
          conversion.toJSONObject()
        )
      }
      this.snackBar.open(`Conversion ${this.conversion.id ? 'Updated' : 'Created'}`, 'Dismiss', {
        duration: 5000
      });
      this.complete.emit(true);
    } else {
      this.snackBar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      });
    }
  }

  getAvailableParameterHelperText() {
    if (this.availableParameters && this.availableParameters.length > 0) {
      return this.availableParameters.reduce((result, current) => {
        if (current) {
          result = result + this.availableParameters.indexOf(current) + ': ' + current + ' \n';
        }
        return result;
      }, '')
    }
  }

  private _getAvailableThirdParties() {
    this.afs.collection('thirdParties')
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(thirdParties$ => {
        this.parents = FirestoreUtilities.mapToType(thirdParties$);
      });
  }

  private _getAvailablePos() {
    this.afs.collection('posSystems')
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(thirdParties$ => {
        this.parents = FirestoreUtilities.mapToType(thirdParties$);
      });
  }

}
