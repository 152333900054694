// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular.json`.

import {FirebaseConfig} from './firebase.config';

export const apiBase = FirebaseConfig.getDevConfiguration().apiBase;
// export const apiBase = 'http://localhost:5001/deliver-sense-app-dev/us-central1';

export const environment = {
    production: false,
    signupEnabled: true,
    applicationControlCode: '22hZl0SMeJfAoKutM1St',
    apiUrl: `${apiBase}/api/`,
    firebaseConfig: FirebaseConfig.getDevConfiguration(),
    applicationVersion: '0.0.2',
};
