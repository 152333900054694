import { FirestoreUtilities } from './../../../utilities/firestore-utilities';
import { ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfirmDialogComponent } from "../../../dialogs/confirm-dialog/confirm-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { FormControl } from "@angular/forms";
import { AuditTrailDocumentSection, AuditTrailDocumentSectionComment, Client, DataConverter, User, Location, AuditTrailDocument, AuditTrailDocumentSectionCommentStatuses } from '@deliver-sense-librarian/data-schema';
import { MatSnackBar } from "@angular/material/snack-bar";
import { combineAll, first, last, takeUntil } from "rxjs/operators";
import { from, Subject } from 'rxjs';
import moment = require("moment");
import { LoadingDialogService } from "../../../services/loading-dialog.service";
import { Papa } from "ngx-papaparse";
import * as _ from 'lodash';
export function downloadDataAsFile(exportData, exportName = 'deliver-sense-export', fileType = 'csv') {
  const dataStr = `${fileType === 'json' ? "data:text/json;charset=utf-8," : "data:text;charset=utf-8,"}` + encodeURIComponent(exportData);
  const downloadAnchorNode = document.createElement('a');
  downloadAnchorNode.setAttribute("href", dataStr);
  downloadAnchorNode.setAttribute("download", exportName + `${fileType === 'json' ? ".json" : ".csv"}`);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

import * as crypto from "crypto-js";
import { CommentStmt } from '@angular/compiler';

@Component({
  selector: 'app-scripter',
  templateUrl: './scripter.component.html',
  styleUrls: ['./scripter.component.scss']
})
export class ScripterComponent implements OnInit, OnDestroy {
  scriptValue = new FormControl();
  destroy$ = new Subject();
  editorOptions = { theme: 'vs-dark', language: 'javascript', scrollBeyondLastLine: false };
  code = `let tax = dataConverter.toNumber(row['tax']);
let mfTax = dataConverter.toNumber(row['mfTax']);
if (tax === 0 && mfTax !== 0) {
    tax = mfTax
}
console.log('tax is: ' + tax);`;

  constructor(private afs: AngularFirestore,
    private snackbar: MatSnackBar,
    private papa: Papa,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog) {
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  onEditorInit() {
    console.log('editor loaded');
    // Manual triggering of change  detection is required to stop showing loading spinner
    this.cdr.detectChanges();
  }

  async runScript() {
    this.loadingService.isLoading(true, 'running...');
    // this.refactorSectionCommentStatusCountDataStructure();

    // @NOTE
    this.snackbar.open('script completed successfully', 'dismiss');

    // @NOTE delete transactions query
    const date = moment().startOf('day').toDate();
    this.loadingService.isLoading(true, 'Querying')
    this.afs.collection('thirdPartyTransactions', ref => ref
      .where('client', '==', 'fG7BAj9WmOxaDgVh2Btf')
      .where('thirdParty', '==', 'p3SJx68gCUADE7yrg5SI')
      .where('dateCreated', ">=", date))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (transactions$) => {
        const transactions = FirestoreUtilities.mapToType(transactions$)
        this.destroy$.next();
        this.destroy$.complete();
        this.loadingService.isLoading(false);
        if (transactions.length > 0) {
          this.loadingService.isLoading(true, 'deleting..');
          const chunks = _.chunk(transactions, 475);
          await Promise.all(chunks.map(async (chunk) => {
            const batch = this.afs.firestore.batch();
            chunk.forEach((transaction: any) => {
              batch.delete(this.afs.doc(`thirdPartyTransactions/${transaction.id}`).ref)
            })
           await batch.commit();
           return;
          }));
          this.loadingService.isLoading(false);
          this.snackbar.open('Transactions deleted', 'Dismiss', { duration: 5000 });
        }
      });


    // this.afs.collection('posTransactions', ref => ref
    //   .where('client', '==', 'fG7BAj9WmOxaDgVh2Btf')
    //   .where('posSystem', '==', '5Y5VyOobpySS3IRlby3T')
    //   .where('dateCreated', ">=", date))
    //   .snapshotChanges()
    //   .pipe(takeUntil(this.destroy$))
    //   .subscribe(async (transactions$) => {
    //     const transactions = FirestoreUtilities.mapToType(transactions$)
    //     this.destroy$.next();
    //     this.destroy$.complete();
    //     this.loadingService.isLoading(false);
    //     if (transactions.length > 0) {
    //       this.loadingService.isLoading(true, 'deleting..');
    //       const chunks = _.chunk(transactions, 475);
    //       await Promise.all(chunks.map(async (chunk) => {
    //         const batch = this.afs.firestore.batch();
    //         chunk.forEach((transaction: any) => {
    //           batch.delete(this.afs.doc(`thirdPartyTransactions/${transaction.id}`).ref)
    //         })
    //        await batch.commit();
    //        return;
    //       }));
    //       this.loadingService.isLoading(false);
    //       this.snackbar.open('Transactions deleted', 'Dismiss', { duration: 5000 });
    //     }
    //   });
    // this.afs.collection('clientDayCharges', ref => ref
    // .where('client', '==', 'p6oij52aJKeL0wwH0Tl2'))
    //   .snapshotChanges()
    //   .subscribe(async (clientDayCharges$) => {
    //     const clientDayCharges = FirestoreUtilities.mapToType(clientDayCharges$);
    //     const chunks = _.chunk(clientDayCharges, 475);
    //     await Promise.all(chunks.map(chunk => {
    //       const batch = this.afs.firestore.batch();
    //       chunk.forEach((charge: any) => {
    //         batch.update(this.afs.doc(`clientDayCharges/${charge.id}`).ref, {
    //           client: 'fG7BAj9WmOxaDgVh2Btf'
    //         });
    //       });
    //       return batch.commit();
    //     }))
    //   })
    // if (this.scriptValue.valid) {
    //   // this.restructureAuditTrails(this.scriptValue.value);
    //   this.collectionDelete();
    // }
    // const dataConverter = new DataConverter();
    // const f = new Function('row', `
    // if(row['sales'] === 0) {
    //   row['sales'] = -row['restaurantTotal']
    // }
    // alert(row['sales'])`);
    // const f = new Function('row', 'dataConverter', this.code);
    // f({tax: '$0', mfTax: '$-123.43'}, dataConverter);
    // const dialogRef = this.dialog.open(ConfirmDialogComponent, {
    //   data: {
    //     title: 'Confirm Delete',
    //     message: 'Are you sure you want to remove this exemption certificate?',
    //     action: 'Yes, delete.'
    //   }
    // });
    // dialogRef.afterClosed().subscribe(confirmed => {
    //   if (confirmed) {
    //     this.changeLocationNumbers();
    //   }
    // });
    // this.afs.collection('users').snapshotChanges()
    //   .subscribe(async (users$) => {
    //     const users = FirestoreUtilities.mapToType(users$);
    //     const clientRoleRequests = users.map(user => {
    //       return this.afs.collection(`users/${user.id}/clientRoles`)snapshot;
    //     });
    //     from(clientRoleRequests)
    //       .pipe(combineAll())
    //       .subscribe(async (clientRoles$) => {
    //         const clientRoles = FirestoreUtilities.mergeCollectionToType(clientRoles$);
    //         const orgRoleRequests = clientRoles.map(clientRole => {
    //           return this.afs.collection(`users/${user.id}/clientRoles/${clientRole.resourec}/organizationRoles`).snapshotChanges();
    //         })
    //       })
    //   });
    // this.afs.collectionGroup('clientRoles')
    //   .snapshotChanges()
    //   .subscribe(async (clientRoles$) => {
    //     const allClientRoles = FirestoreUtilities.mapToType(clientRoles$);
    //     this.afs.collectionGroup('organizationRoles')
    //       .snapshotChanges()
    //       .subscribe(async (orgRoles$) => {
    //         const allOrgRoles = FirestoreUtilities.mapToType(orgRoles$);
    //         const updateClientRolesRequests = this.afs.firestore.batch();
    //         allClientRoles.forEach(clientRole => {
    //           const role = clientRole.role + 1;
    //           console.log(`${clientRole.role} -> ${role}`);
    //         });
    //         const updateOrgRolesRequests = this.afs.firestore.batch();
    //         allClientRoles.forEach(orgRole => {
    //           const role = orgRole.role + 1;
    //           console.log(`${orgRole.role} -> ${role}`);
    //         });
    //         // await Promise.all([updateClientRolesRequests.commit(), updateOrgRolesRequests.commit()]);
    //       })
    //   })
  }
  refactorSectionCommentStatusCountDataStructure() {
    this.afs.collection('auditTrailDocuments')
      .snapshotChanges()
      .pipe(first())
      .subscribe(async (auditTrailDocuments$) => {
        const auditTrailDocuments = <AuditTrailDocument[]>FirestoreUtilities.mapToType(auditTrailDocuments$);
        await Promise.all(auditTrailDocuments.map(async (atDoc) => {
          const sectionsQuery = await this.afs.collection(`auditTrailDocuments/${atDoc.id}/sections`).snapshotChanges().pipe(first()).toPromise();
          const sections = <AuditTrailDocumentSection[]>FirestoreUtilities.mapToType(sectionsQuery);
          return await Promise.all(sections.map(async (section) => {
            const commentsQuery = await this.afs.collection(`auditTrailDocuments/${atDoc.id}/sections/${section.id}/comments`).snapshotChanges().pipe(first()).toPromise();
            const comments = <AuditTrailDocumentSectionComment[]>FirestoreUtilities.mapToType(commentsQuery);
            const totals = {
              totalOpen: 0,
              totalClosed: 0,
              totalReady: 0,
              totalCancelled: 0
            }
            if (comments.length > 0) {
              await Promise.all(comments.map(async (comment) => {
                switch (comment.status) {
                  case AuditTrailDocumentSectionCommentStatuses.cancelled:
                    totals.totalCancelled++;
                    break;
                  case AuditTrailDocumentSectionCommentStatuses.closed:
                    totals.totalClosed++;
                    break;
                  case AuditTrailDocumentSectionCommentStatuses.ready:
                    totals.totalReady++;
                    break;
                  case AuditTrailDocumentSectionCommentStatuses.open:
                    totals.totalOpen++;
                    break;
                }
                console.log(`doc: ${atDoc.name} --> section: ${section.name} --> ${JSON.stringify(totals)}`);
                return await this.afs.doc(`auditTrailDocuments/${atDoc.id}/sections/${section.id}`).update(totals);
              }));
            } else {
              return await this.afs.doc(`auditTrailDocuments/${atDoc.id}/sections/${section.id}`).update(totals);
            }
          }))
        }))
        this.loadingService.isLoading(false);
      })
  }

  /**
   * const userId = this.userId.value;
   this.afs.doc(`users/${userId}`).snapshotChanges().subscribe(async (user$) => {
      const user = FirestoreUtilities.objectToType(user$) as User;
      const clientRoles = user.clientRoles;
      const userClientRoles = [];
      Object.keys(clientRoles).forEach(clientId => {
        if (clientId !== 'dateCreated' && clientId !== 'dateUpdated' && clientId !== 'role') {
          const clientRole = {
            id: clientId,
            resource: clientId,
            role: clientRoles[clientId]['role'],
            organizationRoles: []
          };
          Object.keys(clientRoles[clientId]['entities']).forEach(docId => {
            clientRole.organizationRoles.push({
              id: docId,
              resource: docId,
              type: 'entity',
              role: clientRoles[clientId]['entities'][docId]
            });
          });
          Object.keys(clientRoles[clientId]['locations']).forEach(docId => {
            clientRole.organizationRoles.push({
              id: docId,
              resource: docId,
              type: 'location',
              role: clientRoles[clientId]['locations'][docId]
            });
          })
          Object.keys(clientRoles[clientId]['departments']).forEach(docId => {
            clientRole.organizationRoles.push({
              id: docId,
              resource: docId,
              type: 'department',
              role: clientRoles[clientId]['departments'][docId]
            });
          });
          Object.keys(clientRoles[clientId]['projects']).forEach(docId => {
            clientRole.organizationRoles.push({
              id: docId,
              resource: docId,
              type: 'project',
              role: clientRoles[clientId]['projects'][docId]
            });
          });
          userClientRoles.push(clientRole);
        }
      });
      const userOrgRoleAdds = [];
      const userClientAdds = userClientRoles.map(userClient => {
        userClient.organizationRoles.forEach(orgRole => {
          userOrgRoleAdds.push(
            this.afs.doc(`users/${userId}/clientRoles/${userClient.id}/organizationRoles/${orgRole.id}`).set({
              role: orgRole.role,
              resource: orgRole.resource,
              type: orgRole.type,
            })
          )
        });
        return this.afs.doc(`users/${userId}/clientRoles/${userClient.id}`).set({
          role: userClient.role,
          resource: userClient.id
        });
      });
      await Promise.all([userClientAdds]);
      await Promise.all([userOrgRoleAdds]);
      this.snackbar.open('User roles updated', 'Dismiss', {duration: 5000})
   */
  changeLocationNumbers() {
  }
  private async getCountOfActiveLocationsAndGroupByNumberOfActive3pds() {
    const locationsQuery = await this.afs.collection('locations', ref => ref
      .where('client', '==', 'fG7BAj9WmOxaDgVh2Btf'))
      .snapshotChanges()
      .pipe(first())
      .toPromise();
    const locations = FirestoreUtilities.mapToType(locationsQuery);
    const counts = {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
      5: 0,
      6: 0
    }
    locations.forEach(location => {
      if (location.active) {
        const locationTpds = location['thirdParties'];
        let count = 0;
        locationTpds.forEach(tpd => {
          if (tpd.active) {
            count++;
          }
        });
        console.log(`locationId: ${location.locationId} = ${count}`)
        counts[count]++;
      }
    })
    console.log(counts);
  }
  private async updateMultipleLocationsActive3pd() {
    const locationsToUpdate = [
      1400,
      1600,
      1700,
      1800,
      1900,
      2000,
      2100,
      2200,
      3000,
      3400,
      3600,
      3900,
      4300,
      4400,
      4500,
      4600,
      5000,
      5300,
      5800,
      5900,
      6000,
      6100,
      6500,
      6700,
      6800,
      6900,
      7100,
      7200,
      7300,
      7400,
      7500,
      7700,
      8000,
      8100,
      8400,
      8900,
      9000,
      9200,
      9300,
      9400,
      9500,
      9600,
      9700,
      9799,
      9800,
      9801,
      9803,
      9804,
      9805,
      9808,
      9809,
      9810,
      9814,
      9815,
      9816,
      9817,
      9818,
      9819,
      9820,
      9821,
      9822,
      9825,
      9826,
      9828,
      9829,
      9831,
      9832,
      9838,
      9839,
      9840,
      9841,
      9846,
      9847,
      9848,
      9852,
      9853,
      9856,
      9857,
      9859,
      9862,
      9863,
      9864,
      9872,
      9874,
      9878,
      9880,
      9882,
      9883,
      9884,
      9887,
      9890,
      9891,
      9893,
      9894,
      9895,
      9896,
      9901,
      9902,
      9903,
      9905,
      9906,
      9909,
      9910,
      9912,
      9915,
      9916,
      9918,
      9919,
      9921,
      9922,
      9923,
      9924,
      9926,
      9927,
      9929,
      9930,
      9931,
      9932,
      9933,
      9937,
      9938,
      9944,
      9945,
      9946,
      9948,
      9949,
      9961,
      9963,
      9965,
      9969,
      9970,
      9972,
      9975,
      9976,
      9977,
      9979,
      9980,
      9981,
      9982,
      9984,
      9986,
      9992,
      9994,
      9996,
      9997,
      9998,
    ]
    // @NOTE set multiple locations 3pd to active
    // await Promise.all(locationsToUpdate.map(async (locationId) => {
    //   const digest = crypto.algo.MD5.create();
    //   digest.update(locationId.toString());
    //   digest.update('fG7BAj9WmOxaDgVh2Btf');
    //   const progressiveHash = digest.finalize();
    //   const id = progressiveHash.toString(crypto.enc.Hex);
    //   const location = await this.afs.doc(`locations/${id}`).ref.get()
    //   if (location.exists) {
    //     const location3pds = location.data()['thirdParties'];
    //     const ezCater = location3pds.find(tpd => tpd.thirdParty === 'i9plbf6LMtgDjNJ8Eiak');
    //     const updatedLocation3pds = Object.assign(location3pds);
    //     updatedLocation3pds[updatedLocation3pds.indexOf(ezCater)].active = true;
    //     return await this.afs.doc(`locations/${id}`).update({
    //       thirdParties: updatedLocation3pds
    //     })
    //   }
    // }));

  }
  // async restructureAuditTrails(auditTrailDocumentId: string) {
  //   this.afs.collection('auditTrailDocumentTabs', ref => ref
  //     .where('auditTrailDocument', '==', auditTrailDocumentId))
  //     .snapshotChanges()
  //     .subscribe(auditTrailDocumentTabs$ => {
  //       const auditTrailDocumentTabs = FirestoreUtilities.mapToType(auditTrailDocumentTabs$);
  //       const commentRequests = auditTrailDocumentTabs.map((auditTrailDocumentTab: AuditTrailDocumentSection) => {
  //         return this.afs.collection('auditTrailDocumentTabComments', ref => ref.where('auditTrailDocumentTab', '==', auditTrailDocumentTab.id))
  //           .snapshotChanges();
  //       });
  //       from(commentRequests)
  //         .pipe(combineAll())
  //         .subscribe(async (auditTrailDocumentTabComments$) => {
  //           const restructuringBatch = this.afs.firestore.batch();
  //           const auditTrailDocumentTabComments = FirestoreUtilities.mergeCollectionToType(auditTrailDocumentTabComments$);
  //           auditTrailDocumentTabs.forEach((auditTrailDocumentTab: AuditTrailDocumentSection) => {
  //             restructuringBatch.set(this.afs.doc(`auditTrailDocuments/${auditTrailDocumentId}/sections/${auditTrailDocumentTab.id}`).ref, {
  //               name: auditTrailDocumentTab.name,
  //               notes: auditTrailDocumentTab.notes ? auditTrailDocumentTab.notes : '',
  //               order: auditTrailDocumentTab.order ? auditTrailDocumentTab.order : 0
  //             });
  //             const sectionComments = auditTrailDocumentTabComments.filter(comment => comment.auditTrailDocumentTab === auditTrailDocumentTab.id);
  //             sectionComments.forEach((sectionComment: AuditTrailDocumentSectionComment) => {
  //               restructuringBatch.set(this.afs.doc(`auditTrailDocuments/${auditTrailDocumentId}/sections/${auditTrailDocumentTab.id}/comments/${sectionComment.id}`).ref,
  //                 {
  //                   assignee: sectionComment.assignee,
  //                   creator: sectionComment.creator,
  //                   order: sectionComment.order ? sectionComment.order : 0,
  //                   dueDate: sectionComment.dueDate,
  //                   status: sectionComment.status,
  //                   comment: sectionComment.message
  //                 });
  //               if (sectionComment.response) {
  //                 restructuringBatch.set(this.afs.doc(`auditTrailDocuments/${auditTrailDocumentId}/sections/${auditTrailDocumentTab.id}/comments/${sectionComment.id}/messages/${this.afs.createId()}`)
  //                   .ref, {
  //                   dateCreated: moment().toDate(),
  //                   from: sectionComment.assignee,
  //                   message: sectionComment.response
  //                 })
  //               }
  //             })
  //           })
  //           return await restructuringBatch.commit();
  //         })
  //     });
  // }
  //
  // private async collectionDelete() {
  //   const startDate = moment(new Date('12/1/2019')).toDate();
  //   const endDate = moment(new Date('1/1/2020')).toDate();
  //   let thirdPartyTransactions = [];
  //   let pageMarker;
  //   this.loadingService.isLoading(true);
  //   const firstQuery = await this.firstQuery();
  //   thirdPartyTransactions = [...thirdPartyTransactions, ...firstQuery];
  //   pageMarker = firstQuery[firstQuery.length - 1];
  //   let nextSet;
  //   let lastSet = false;
  //   while (!lastSet) {
  //     nextSet = await this.nextQuery(pageMarker);
  //     thirdPartyTransactions = [...thirdPartyTransactions, ...nextSet];
  //     if (nextSet.length < 1000) {
  //       lastSet = true;
  //     } else {
  //       pageMarker = nextSet[nextSet.length - 1];
  //     }
  //   }
  //   console.log(thirdPartyTransactions.length);
  //   this.loadingService.isLoading(false);
  //   this.loadingService.isLoading(true, 'Deleting documents');
  //   const chunks = _.chunk(thirdPartyTransactions, 400);
  //   await chunks.map(async (chunk) => {
  //     const deleteBatch = this.afs.firestore.batch();
  //     chunk.forEach((doc) => {
  //       deleteBatch.delete(this.afs.doc(`thirdPartyTransactions/${doc.id}`).ref)
  //     });
  //     return await deleteBatch.commit();
  //   });
  //   this.loadingService.isLoading(false);
  // }
  //
  // private async firstQuery() {
  //   const start = moment(new Date('12/1/2019')).toDate();
  //   const end = moment(new Date('1/1/2020')).toDate();
  //   const uberTransactions$ = await this.afs.collection(`thirdPartyTransactions`, ref => ref
  //     .where('thirdParty', '==', 'TcVJpdbCUWAZo5xK0y98')
  //     .where('client', '==', 'fG7BAj9WmOxaDgVh2Btf')
  //     // .where('date', '>=', start)
  //     // .where('date', '<=', end)
  //     .orderBy('date')
  //     .limit(1000)
  //   ).snapshotChanges()
  //     .pipe(first())
  //     .toPromise();
  //   return FirestoreUtilities.mapToType(uberTransactions$);
  // }
  //
  // private async nextQuery(pageMarker) {
  //   const start = moment(new Date('12/1/2019')).toDate();
  //   const end = moment(new Date('1/1/2020')).toDate();
  //   const uberTransactions$ = await this.afs.collection(`thirdPartyTransactions`, ref => ref
  //     .where('thirdParty', '==', 'TcVJpdbCUWAZo5xK0y98')
  //     .where('client', '==', 'fG7BAj9WmOxaDgVh2Btf')
  //     // .where('date', '>=', start)
  //     // .where('date', '<=', end)
  //     .orderBy('date')
  //     .startAfter(pageMarker.date)
  //     .limit(1000)
  //   ).snapshotChanges()
  //     .pipe(first())
  //     .toPromise();
  //   return FirestoreUtilities.mapToType(uberTransactions$);
  // }



}
