import {AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../utilities/firestore-utilities";
import {Subject} from "rxjs";
import {AngularFireStorage} from '@angular/fire/storage';
import * as jsyaml from 'js-yaml';
import {MatSnackBar} from "@angular/material/snack-bar";
declare const SwaggerEditorBundle: any;
declare const SwaggerEditorStandalonePreset: any;

@Component({
  selector: 'app-swagger-editor',
  templateUrl: './swagger-editor.component.html',
  styleUrls: ['./swagger-editor.component.scss']
})
export class SwaggerEditorComponent implements OnInit, OnDestroy, AfterViewInit, AfterViewChecked {
  private destroy$ = new Subject();
  private apiObject: any;
  private editor: any;
  private url: string;
  public changes: string;
  private specIsJson = true;
  constructor(private afs: AngularFirestore,
              private storage: AngularFireStorage,
              private snackbar: MatSnackBar) {
  }

  ngOnInit() {
    this.afs.doc(`staticContents/apiV1`)
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(apiV1 => {
        const docs = FirestoreUtilities.objectToType(apiV1);
        if (docs) {
          this.apiObject = JSON.parse(docs.content);
          this.editor = null;
        }
      });
    // this.getApiDocLocation();
  }

  ngAfterViewInit(): void {

  }

  ngAfterViewChecked(): void {
    if (!this.editor && this.apiObject) {
      this.editor = SwaggerEditorBundle({
        dom_id: '#swagger-editor',
        layout: 'StandaloneLayout',
        presets: [
          SwaggerEditorStandalonePreset
        ],
        plugins: [
          this.specUpdateListenerPlugin()
        ],
        spec: this.apiObject
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  convertJsonToYaml(spec) {
    // const yaml = yamljs.parse(spec);
    // debugger;
    // return yaml;
    return spec;
  }

  async save() {
    const yaml = jsyaml.load(this.changes);
    await this.afs.doc(`static/apiV1`).update({content: yaml});
    this.snackbar.open('Successfully update api docs', 'Dismiss', {
      duration: 5000
    })
  }

  specUpdateListenerPlugin() {
    return {
      statePlugins: {
        spec: {
          wrapActions: {
            updateSpec: (oriAction) => {
              return  (spec) => {
                // if (this.specIsJson) {
                //   const parsed = this.convertJsonToYaml(spec);
                //   return oriAction(parsed);
                // } else {
                  this.changes = spec;
                  return oriAction(spec);
                // }
              }
            }
          }
        }
      }
    }
  }

  private async getApiDocLocation() {
    this.url = await this.storage.storage.ref('static/openapi.yaml').getDownloadURL();
  }
}
