import { FormControl } from '@angular/forms';
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild, Input } from '@angular/core';
import { FirebaseAuthService } from "../../../../../auth/services/firebase-auth.service";
import { Papa } from "ngx-papaparse";
import { MatCheckboxChange, MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource } from "@angular/material";
import { AngularFirestore } from '@angular/fire/firestore';
import { ThirdParty, Location, LocationThirdParty, LocationThirdPartyItemRate, RateType, User, UserLocationRole, Client, ClientRole, usStates } from '@deliver-sense-librarian/data-schema';
import { FirestoreUtilities } from "../../../../../utilities/firestore-utilities";
import { combineAll, map, takeUntil, distinctUntilChanged } from "rxjs/operators";
import { combineLatest, from, Subject } from "rxjs";
import { ConfirmDialogComponent } from "../../../../../dialogs/confirm-dialog/confirm-dialog.component";
import { Store } from '@ngrx/store';
import { LocationUploadDialogComponent } from "../../../../../dialogs/location-upload-dialog/location-upload-dialog.component";
import { UiState } from "../../../../../redux/custom-states/uiState/ui-state";
import * as moment from "moment";
import { LoadingDialogService } from "../../../../../services/loading-dialog.service";
import { trigger, state, style, transition, animate } from '@angular/animations';
export const tableExpandAnimation = [
  trigger('detailExpand', [
    state('collapsed', style({ height: '0px', minHeight: '0' })),
    state('expanded', style({ height: '*' })),
    transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
  ]),
];
@Component({
  selector: 'app-client-locations',
  templateUrl: './client-locations.component.html',
  styleUrls: ['./client-locations.component.scss'],
  animations: tableExpandAnimation
})
export class ClientLocationsComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<Location>;
  public mutableTableData: MatTableDataSource<any> & MatTableDataSource<Location>;
  public displayedColumns: string[] = ['id', 'name', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'totalTaxRate', 'expandTrigger'];
  public isInEditMode = false;
  private _user: User;
  private _locations: Location[] = [];
  private _locationThirdParties: LocationThirdParty[] = [];
  private _locationThirdPartyRates: LocationThirdPartyItemRate[] = [];
  public teamMembers: User[] = [];
  expandedElement: any | null;
  private _destroy$ = new Subject();
  public thirdParties: ThirdParty[] = [];
  public rateTypes: RateType[] = [];
  public uiState: UiState;
  filterState = new FormControl();
  locationSearchText = new FormControl();
  states = usStates;
  private ldprsToChange = {};
  private ldpsToChange = {};
  private locationsToChange = {};

  private _ldpActiveChanges = {};
  private _client: Client;
  private locationsInitialized = false;
  noLocations: boolean;
  showUpload = false;

  constructor(private store: Store<any>,
    private papa: Papa,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private _cdr: ChangeDetectorRef) {
  }

  ngOnInit() {
    this._fetchLocations();
    this._fetchThirdPartiesAndRateTypes();
    this._listenForStateFilter();
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  _listenForStateFilter() {
    this.filterState.valueChanges.subscribe(selectedState => {
      //   if (selectedState) {
      //     const tableFilters = [];
      //     tableFilters.push({
      //       id: 'addressState',
      //       value: selectedState
      //     });
      //     this.tableData.filter = JSON.stringify(tableFilters);
      //   } else {
      //     this.tableData.filter = '';
      //   }
      //   if (this.tableData.paginator) {
      //     this.tableData.paginator.firstPage();
      //   }
      this.locationSearchText.reset();
      this._mergeDataForTable();
    })
  }

  applyAllFilter(filterValue: string) {
    const tableFilters = [
      {
        id: 'locationId',
        value: filterValue
      },
      {
        id: 'name',
        value: filterValue
      },
      {
        id: 'addressLine1',
        value: filterValue
      },
      {
        id: 'addressLine2',
        value: filterValue
      },
      {
        id: 'addressCity',
        value: filterValue
      },
      {
        id: 'addressPostalCode',
        value: filterValue
      },
    ];
    this.tableData.filter = JSON.stringify(tableFilters);
    if (this.tableData.paginator) {
      this.tableData.paginator.firstPage();
    }
  }

  private _fetchLocations() {

    this.afs.collection('locations', ref => ref
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(locations$ => {
        this._locations = FirestoreUtilities.mapToType(locations$);
        this.locationsInitialized = true;
        this._mergeDataForTable();
        this.loadingService.isLoading(false);
      }, (e) => {
        this.loadingService.isLoading(false);
        this.snackBar.open('Error retrieving locations. Please refresh and try again.', 'Dismiss', {
          duration: 5000
        });
        console.log(`error at get locations: ${e.message}`)
      })
  }

  private _mergeDataForTable() {
    const locations = Object.assign(this.filterState.value ? this._locations.filter(_location => _location.addressState === this.filterState.value) : this._locations);
    this.tableData = new MatTableDataSource<Location>(locations);
    this.mutableTableData = Object.assign(new MatTableDataSource(), this.tableData);
    this.tableData.paginator = this.paginator;
    this.tableData.sort = this.sort;
    this.tableData.filterPredicate =
      (data: Location, filtersJson: string) => {
        const matchFilter = [];
        const filters = JSON.parse(filtersJson);

        filters.forEach(filter => {
          const val = data[filter.id] === null ? '' : data[filter.id];
          matchFilter.push(val.toLowerCase().includes(filter.value.toLowerCase()));
        });
        return matchFilter.indexOf(true) > -1;
      };
    this._cdr.detectChanges();
  }

  private _fetchThirdPartiesAndRateTypes() {
    const fetchThirdPartys = this.afs.collection('thirdParties').snapshotChanges();
    const fetchRateTypes = this.afs.collection('rateTypes').snapshotChanges();
    combineLatest([
      fetchThirdPartys,
      fetchRateTypes
    ]).pipe(takeUntil(this._destroy$))
      .subscribe(([thirdParties$, rateTypes$]) => {
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
        this.rateTypes = FirestoreUtilities.mapToType(rateTypes$)
          .filter(rate => {
            return rate.name !== 'Beer' &&
              rate.name !== 'Non-Taxable' &&
              rate.name !== 'Wine' &&
              rate.name !== 'Liquor'
          })
          .sort((a, b) => {
            return a.name === 'Food & N/A Bev' ? -1 : 1;
          });

      })
  }

  getLocationThirdParties(location: Location) {
    return location['thirdParties'] ? location['thirdParties'] : []
  }

  isThirdPartyAvailableForLocation(location: any, thirdParty: ThirdParty) {
    const isDpAvailalbe = this.getLocationThirdParties(location).find(ldp => ldp.thirdParty === thirdParty.id);
    return isDpAvailalbe ? isDpAvailalbe.active : false;
  }

  getThirdPartyDeliveryFee(thirdParty: ThirdParty, location: Location) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      return ldp.deliveryFee;
    }
    return 0;
  }

  getThirdPartyMfRate(thirdParty: ThirdParty, location: Location) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      return ldp['marketFacilitatorRates'] && ldp['marketFacilitatorRates'][0] ? ldp['marketFacilitatorRates'][0].rate : 0;
    }
    return 0;
  }
  getThirdPartyMfEffectiveDate(thirdParty: ThirdParty, location: Location) {
    const ldp = this.getLocationThirdParties(location).find(_ldp => _ldp.thirdParty === thirdParty.id);
    if (ldp) {
      return ldp['marketFacilitatorRates'] && ldp['marketFacilitatorRates'][0] && ldp['marketFacilitatorRates'][0].effectiveDate ?
        `Effective Date: ${moment(ldp['marketFacilitatorRates'][0].effectiveDate.toDate()).format('M/DD/YY')}` :
        null;
    }
    return null;
  }
  getTotalTaxRate(row: any) {
    return (+row.stateTaxRate + +row.cityTaxRate + +row.countyTaxRate + +row.specialTaxRate).toFixed(2);
  }

  openLocationUploadDialog() {
    this.dialog.open(LocationUploadDialogComponent, {
      panelClass: 'invisible-panel-dialog'
    });
  }
}
