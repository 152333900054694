import { Component, EventEmitter, Output } from '@angular/core';

import * as screenfull from 'screenfull';
import {FirebaseAuthService} from "../../../auth/services/firebase-auth.service";
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html'
})
export class HeaderComponent {
  @Output()
  toggleSidenav = new EventEmitter<void>();
  @Output()
  toggleNotificationSidenav = new EventEmitter<void>();

  constructor(private router: Router, private auth: FirebaseAuthService) {}

  fullScreenToggle(): void {
    if (screenfull.enabled) {
      screenfull.toggle();
    }
  }

  async logout() {
    await this.auth.signOut();
    this.router.navigate(['']);
    }
}
