import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {LoginComponent} from './login/login.component';
import {RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings} from "ng-recaptcha";
import {DsSharedModule} from "../shared/ds-shared.module";
import {NgbAlertModule} from "@ng-bootstrap/ng-bootstrap";
import {RouterModule} from "@angular/router";
import {MaterialModule} from "../material-module.module";

@NgModule({
    declarations: [LoginComponent],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RecaptchaModule.forRoot(), // Keep in mind the "forRoot"-magic nuances!
        RecaptchaFormsModule,
        DsSharedModule,
        NgbAlertModule,
        RouterModule,
        MaterialModule,
        FormsModule
    ],
    providers: [
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {
                siteKey: '6LcfNbkUAAAAAEz20Rw2LMS8NAjPXA9eG-SZ89DF',
            } as RecaptchaSettings,
        },
    ]
})
export class AuthModule {
}
