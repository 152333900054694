import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FirebaseAuthService } from "../../../../auth/services/firebase-auth.service";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { ActivatedRoute, Router } from "@angular/router";
import { takeUntil } from "rxjs/operators";
import { Subject, combineLatest } from 'rxjs';
import { animate, state, style, transition, trigger } from "@angular/animations";
import { ThirdParty, PosSystem } from '@deliver-sense-librarian/data-schema';
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: 'app-client-mappings',
  templateUrl: './client-3pd.component.html',
  styleUrls: ['./client-3pd.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ]
})
export class Client3pdComponent implements OnInit, OnDestroy {
  public client: any;
  public selectedThirdParty: ThirdParty;
  public selectedPosSystem: PosSystem;
  public thirdParties: ThirdParty[] = [];
  public posSystems: PosSystem[] = [];
  private _destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params$ => {
      this.afs.doc(`clients/${params$['id']}`)
        .snapshotChanges()
        .pipe(takeUntil(this._destroy$))
        .subscribe(client$ => {
          this.client = FirestoreUtilities.objectToType(client$);
        });
      combineLatest(
        this.afs.collection('thirdParties').snapshotChanges(),
        this.afs.collection('posSystems').snapshotChanges()
      )
        .pipe(takeUntil(this._destroy$))
        .subscribe(([thirdParties$, posSystems]) => {
          this.thirdParties = <ThirdParty[]>FirestoreUtilities.mapToType(thirdParties$);
          this.posSystems = <PosSystem[]>FirestoreUtilities.mapToType(posSystems);
        });
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  setPosSystem($event: MatSelectChange) {
    this.selectedPosSystem = null;
    setTimeout(() => {
      this.selectedPosSystem = $event.value;
    })
  }

  setThirdParty($event: MatSelectChange) {
    this.selectedThirdParty = null;
    setTimeout(() => {
      this.selectedThirdParty = $event.value;
    })
  }
}
