import { environment } from './../../../../environments/environment';
import { INITIAL_UI_STATE, UiState } from './ui-state';
import { Action } from '@ngrx/store';
import { UNAUTHENTICATE_USER } from './ui-state-actions/authentication-actions';
import {
    AUTHENTICATION_FAILED,
    AUTHENTICATION_SUCCESS,
    LOADED_CLIENT,
    LoadedClientAction, SET_ACCOUNT_CLIENT, SetAccountClientAction, UnauthenticateUserAction,
    UNAUTHENTICATION_SUCCESS,
    UserAuthenticationSuccessAction
} from './ui-state-actions';

export function uiState(state: UiState = INITIAL_UI_STATE, action: Action): UiState {
    switch (action.type) {
        /**
         * Authentication
         */
        case AUTHENTICATION_SUCCESS:
      return handleSetAuthenticatedUserAction(state, <any>action);
    case AUTHENTICATION_FAILED:
      return handleSetAuthenticationError(state, <any>action);
    case UNAUTHENTICATE_USER:
      return handleUnauthenticatedUserAction(state, <any>action);
            case SET_ACCOUNT_CLIENT:
            return handleSetAccountClientAction(state, <any>action);
        /**
         * Default
         */
        default:
            return state;
    }

}
function handleSetAuthenticatedUserAction(state: UiState, action: UserAuthenticationSuccessAction) {
  const newState = Object.assign({}, state);
  localStorage.setItem('user', JSON.stringify(action.payload));
  localStorage.setItem('applicationVersion', JSON.stringify(environment.applicationVersion));
  newState.authUser = action.payload;
  return newState;
}


// @TODO set authentication error here
function handleSetAuthenticationError(state: UiState, action: UserAuthenticationSuccessAction) {
    const newState = Object.assign({}, state);
    return newState;
}

function handleUnauthenticatedUserAction(state: UiState, action: UnauthenticateUserAction) {
  unsetAuthStorage();
  const newState = Object.assign({}, state);
  newState.authUser = undefined;
  return newState;
}

function handleSetAccountClientAction(state: UiState, action: SetAccountClientAction) {
    const newState = Object.assign({}, state);
    newState.currentClient = action.payload;
    return newState;
}


function unsetAuthStorage() {
  localStorage.removeItem('user');
}












