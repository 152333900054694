import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';
import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ThirdParty, PosSystem, DataConverter } from '@deliver-sense-librarian/data-schema';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { Subject } from 'rxjs';
import { Conversion } from '@deliver-sense-librarian/data-schema/dist/models/interfaces';
import { FirebaseAuthService } from 'app/auth/services/firebase-auth.service';
import { Papa } from 'ngx-papaparse';
import { FormBuilder } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { AngularFirestore } from '@angular/fire/firestore';
import { TpdConversionDialogComponent } from '../../../dialogs/tpd-conversion-dialog/tpd-conversion-dialog.component';
import { trigger, state, style, transition, animate } from '@angular/animations';

@Component({
  selector: 'app-tpd-mappings-table',
  templateUrl: './tpd-mappings-table.component.html',
  styleUrls: ['./tpd-mappings-table.component.scss'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({ height: '0px', minHeight: '0' })),
      state('expanded', style({ height: '*' })),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TpdMappingsTableComponent implements OnInit {
  @Input() thirdPartyId: string;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['keyOut', 'keyIn', 'parent', 'method', 'params', 'edit'];
  public expandedElement: any | null;
  public parents: ThirdParty[] | PosSystem[] = [];
  public fullConversionResults: any[];
  public showFullTest: boolean;
  public thirdParty: ThirdParty;
  private _destroy$ = new Subject();
  private conversions: Conversion[] = [];
  private currentOutput: string | 'invalid rule';
  private _dataConverter = new DataConverter();
  fieldsAlreadyMapped: any[] = [];

  constructor(
    private auth: FirebaseAuthService,
    private papa: Papa,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.afs.doc(`thirdParties/${this.thirdPartyId}`).snapshotChanges().pipe(takeUntil(this._destroy$)).subscribe(thirdParty$ => {
      this.thirdParty = <ThirdParty>FirestoreUtilities.objectToType(thirdParty$);
      this.fieldsAlreadyMapped = this.thirdParty.reportConversionMappings.map(conversion => {
        return conversion.keyOut;
      })
      this.tableData = new MatTableDataSource(this.thirdParty.reportConversionMappings);
    })
  }

  editConversion(conversion) {
    this.dialog.open(TpdConversionDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        unavailableFields: this.fieldsAlreadyMapped,
        thirdParty: this.thirdParty,
        conversion,
      }
    })
  }

  createConversion() {
    this.dialog.open(TpdConversionDialogComponent, {
      panelClass: 'invisible-panel-dialog',
      data: {
        unavailableFields: this.fieldsAlreadyMapped,
        thirdParty: this.thirdParty,
        conversion: null,
      }
    })
  }


  public getRelatedParentName(conversion: any) {
    const parent = this.parents.find(dp => dp.id === conversion.thirdParty || dp.id === conversion.posSystem);
    return parent ? parent.name : '';
  }

  convertCSVToJson(csv): Promise<any[]> {
    return new Promise(resolve => {
      this.papa.parse(csv, {
        header: true,
        worker: true,
        complete: (result) => {
          resolve(result.data);
        }
      });
    });
  }

  async getConversion(csv: any, conversion: Conversion) {
    const jsonValue = await this.convertCSVToJson(csv);
    const values = jsonValue ? jsonValue[0] : {};
    if (conversion.algorithm[0].method === 'code') {
      this.currentOutput = this._dataConverter.applyAlgorithm(jsonValue[0], conversion.algorithm[0].code);
    } else {
      const fields = conversion.algorithm[0].fields.map(fieldName => {
        const fieldValue = values[fieldName];
        return fieldValue;
      });
      const conversionResult = this._dataConverter.convertData(fields, conversion.algorithm[0].method, conversion.algorithm[0].methodParameters);
      this.currentOutput = conversionResult ? conversionResult : conversion.algorithm[0].default;
    }
  }

  async testFullConversion(csv: string) {
    const jsonValue = await this.convertCSVToJson(csv);
    const result = [];
    jsonValue.forEach(originalObject => {
      const convertedObject = {};
      this.thirdParty.reportConversionMappings.forEach(conversion => {
        if (conversion.algorithm && conversion.algorithm[0].method === 'code') {
          convertedObject[conversion.keyOut] = this._dataConverter.applyAlgorithm(originalObject, conversion.algorithm[0].code);
        } else if (conversion.algorithm && conversion.algorithm[0].method && conversion.algorithm[0].fields) {
          const fieldValues = conversion.algorithm[0].fields.map(fieldName => {
            const fieldValue = originalObject[fieldName];
            return fieldValue;
          });
          if (fieldValues && fieldValues[0]) {
            const convertedValue = this._dataConverter.convertData(fieldValues, conversion.algorithm[0].method, conversion.algorithm[0].methodParameters);
            convertedObject[conversion.keyOut] = convertedValue !== undefined || convertedValue !== null || !isNaN(convertedValue)
              ? convertedValue :
              conversion.algorithm[0].default ?
                conversion.algorithm[0].default :
                'Conversion Error'
          } else {
            convertedObject[conversion.keyOut] = originalObject[conversion.keyIn];
          }
        } else {
          convertedObject[conversion.keyOut] = originalObject[conversion.keyIn];
        }
      });
      result.push(convertedObject);
    });
    this.fullConversionResults = result;
  }

  getConversionValues(row: any) {
    if (row.algorithm && row.algorithm[0].fields && row.algorithm[0].fields.length > 0) {
      return row.algorithm[0].fields.reduce((result, curr) => {
        return result + curr + ', ';
      }, '');
    } else {
      return row.keyIn;
    }
  }

  getMethodParams(row: any) {
    if (row.algorithm && row.algorithm[0].methodParameters && row.algorithm[0].methodParameters.length > 0) {
      return row.algorithm[0].methodParameters.reduce((result, curr) => {
        return result + curr + ', ';
      }, '');
    } else {
      return '';
    }
  }
}
