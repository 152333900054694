import CustomScrollbarOptions = MCustomScrollbar.CustomScrollbarOptions;

export function markFields(form) {
    Object.keys(form.controls).forEach(field => {
        form.get(field).markAsTouched({onlySelf: true});
    });
}

export const scrollbarOptions = <CustomScrollbarOptions>{axis: 'y', theme: 'minimal-dark'};

export function getErrorCodeMessage(code) {
    const errorCodeMap = {
        1: 'There was a change to the prices or fees in your cart. Refresh and try again.',
        2: 'One or more of the promo codes you entered are no longer valid.',
        3: 'One or more of the products in your cart are no longer valid.',
        4: 'Refund failed... One or more collaborators on this project have an insufficient account/balance.',
        5: 'Refund failed... please refresh and try again.',
        6: 'One or more items in the cart do not allow international shipping',
        7: 'master code provided is incorrect',
        8: 'professional does not have bank account information'
    };
    return errorCodeMap[code] ? errorCodeMap[code] : 'Unknown error occurred.';
}

export const staticImageLocations = {
    amexLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Famex_logo.png?alt=media&token=68517f1c-174c-40b3-ae36-243433c375f3',
    visaLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvisa.png?alt=media&token=00ee179e-6bb9-4ba2-877e-c198dbb1503a',
    discoverLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-professional.appspot.com/o/static%2Fdiscover_logo.jpg?alt=media&token=842f9169-8033-4691-a353-c6f5cc2f3206',
    masterCardLogo: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fmc_logo.png?alt=media&token=369d153f-cff2-4798-821c-6a706b1f28bc',
    imagePlaceholder: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fimage-placeholder.png?alt=media&token=238f185d-cfb9-4f07-8bf3-61b25b9bc007',
    lpSecondImage: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Flight_and_dark.jpg?alt=media&token=43fe5c25-7f5f-4de7-97a0-4b075395cfc0',
    lpMainImage: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvexli_car_hood.jpg?alt=media&token=0bb73ace-1244-4b4a-b0af-f78efca46f10',
    logoLarge: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo-medium.png?alt=media&token=f5d41819-8886-4edc-94dd-79e860d7850d',
    logoLargeWhite: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo-medium-white.png?alt=media&token=bce2639c-bfa1-4552-bdb2-9cc2a68a06b4',
    logoXtraLarge: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo_large.png?alt=media&token=907ba9cf-4d74-4393-a8c8-defaaf9b95bb',
    logoXtraLargeWhite: 'https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Flogo_large_white.png?alt=media&token=e6691f0a-84a4-4ec4-8641-d8c11e7812b8',
    iconOfficial: "https://firebasestorage.googleapis.com/v0/b/deliver-sense-web-test.appspot.com/o/static%2Ficon-512x512.png?alt=media&token=9ba2e21d-f664-4a8e-a6f9-6c07f9075d44",
    offeringPlaceholder: "https://firebasestorage.googleapis.com/v0/b/prodville-test.appspot.com/o/static%2Foffering-placeholder.png?alt=media&token=49ecba43-627e-4ab5-b7e8-d5855ea2c30d",
    videoPlaceholder: 'https://firebasestorage.googleapis.com/v0/b/vexed-creative.appspot.com/o/static%2Fvideo-sample-placeholder.png?alt=media&token=c272b052-1235-4087-945f-b6e9008d3e61',
    cookie: 'https://firebasestorage.googleapis.com/v0/b/vexed-professional-lab.appspot.com/o/static%2Fcookie.png?alt=media&token=a81d25a2-0655-4659-85b8-877669ba7b36'
};

export const placeholderUrl = staticImageLocations.imagePlaceholder;
export const cardImageSources = {
    Visa: staticImageLocations.visaLogo,
    Discover: staticImageLocations.discoverLogo,
    MasterCard: staticImageLocations.masterCardLogo,
    "American Express": staticImageLocations.amexLogo
};
export const getPlaceholderImage = {url: staticImageLocations.offeringPlaceholder};
