import { Component, OnInit, Input } from '@angular/core';
import { Client } from '@deliver-sense-librarian/data-schema';

@Component({
  selector: 'app-client-entities',
  templateUrl: './client-entities.component.html',
  styleUrls: ['./client-entities.component.scss']
})
export class ClientEntitiesComponent implements OnInit {
  @Input() client: Client;
  constructor() { }

  ngOnInit() {
  }

}
