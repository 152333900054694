
export class FirebaseConfig {
    static getDevConfiguration() {
        return  {
            apiKey: "AIzaSyDMz5mEUvc_pXwVJ7ca5xkO78yIkdjs3gA",
            authDomain: "deliver-sense-app-dev.firebaseapp.com",
            databaseURL: "https://deliver-sense-app-dev.firebaseio.com",
            projectId: "deliver-sense-app-dev",
            storageBucket: "deliver-sense-app-dev.appspot.com",
            messagingSenderId: "1099235724703",
            appId: "1:1099235724703:web:91a828cf87e0f3fc2581aa",
            apiBase: 'https://us-central1-deliver-sense-app-dev.cloudfunctions.net'
        };
    }

    static getPrepConfiguration() {
        return  {
            apiKey: "AIzaSyD2KQ4HVals4qeg48132OW9CCoJShewcKo",
            authDomain: "deliver-sense-app-pre-prod.firebaseapp.com",
            databaseURL: "https://deliver-sense-app-pre-prod.firebaseio.com",
            projectId: "deliver-sense-app-pre-prod",
            storageBucket: "deliver-sense-app-pre-prod.appspot.com",
            messagingSenderId: "850061068460",
            appId: "1:850061068460:web:faa9b57642ce6ecbfbf709",
            measurementId: "G-E97Q7YPRJT",
            apiBase: 'https://us-central1-deliver-sense-app-pre-prod.cloudfunctions.net'
          };
    }
    static getProdConfiguration() {
        return  {
            apiKey: "AIzaSyDeNeMTWygQ1TENd6aaq0e-Tnz3lmjjzL4",
            authDomain: "deliver-sense.firebaseapp.com",
            databaseURL: "https://deliver-sense.firebaseio.com",
            projectId: "deliver-sense",
            storageBucket: "deliver-sense.appspot.com",
            messagingSenderId: "904223904616",
            appId: "1:904223904616:web:5ce87f7c03ab8d60ed7cdd",
            apiBase: 'https://us-central1-deliver-sense.cloudfunctions.net'
        };
    }
}
