import { FirestoreUtilities } from '../../../../../utilities/firestore-utilities';
import { takeUntil } from 'rxjs/operators';
import { Validators, FormControl } from '@angular/forms';
import { Component, OnInit, Input } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseAuthService } from 'app/auth/services/firebase-auth.service';
import { MatDialog, MatSnackBar, MatCheckboxChange } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import * as moment from 'moment';
import { Client, DsModules, ClientModule, thirdPartyTransactionFields } from '@deliver-sense-librarian/data-schema';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-tpd-initialize',
  templateUrl: './tpd-initialize.component.html',
  styleUrls: ['./tpd-initialize.component.scss']
})
export class TpdInitializeComponent implements OnInit {
  @Input() client: Client;
  public client3PDModule: ClientModule;
  public thirdParties = [
    { id: '672yIkYBvshhag2kNFIb', name: 'GrubHub' },
    { id: 'TcVJpdbCUWAZo5xK0y98', name: 'Uber Eats' },
    { id: 'VTLusJQD70OEmMMneeZL', name: 'GrubHub work' },
    { id: 'eriE4HrtHdbFIUdm7JWC', name: 'PostMates' },
    { id: 'p3SJx68gCUADE7yrg5SI', name: 'DoorDash' }
  ]

  public selectedThirdParties = new FormControl([], Validators.required);
  destroy$ = new Subject();

  constructor(private auth: FirebaseAuthService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.afs.collection('clientModules', ref => ref
      .where('client', '==', this.client.id)
      .where('module', '==', DsModules.ThirdPartyDeliveryReporting))
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(clientTpdModule$ => {
        this.client3PDModule = <ClientModule>FirestoreUtilities.mapToType(clientTpdModule$)[0];
      })
  }

  public async generateBase3PDMappings() {
    if (this.selectedThirdParties.valid) {
      await Promise.all(this.selectedThirdParties.value.map(async (thirdPartyId$) => {
        const thirdParty = await this.afs.doc(`thirdParties/${thirdPartyId$}`).valueChanges().pipe(first()).toPromise();
        if (thirdParty['reportConversionMappings']) {
          await Promise.all(thirdParty['reportConversionMappings'].map(async (conversionMapping) => {
            conversionMapping.dateCreated = moment().toDate();
            conversionMapping.dateUpdated = moment().toDate();
            if (conversionMapping.algorithm) {
              conversionMapping.algorithm[0].dateCreated = moment().toDate();
              conversionMapping.algorithm[0].dateUpdated = moment().toDate();
            }
            conversionMapping.client = this.client.id;
            return await this.afs.collection('clientThirdPartyTransactionConversions').add(
              conversionMapping
            );
          }));
        }
      }));
      this.snackBar.open('Successfully setup client 3pd inital mappings', 'Dismiss', { duration: 5000 });
    }
  }
  async toggleNeedsSetup($event: MatCheckboxChange) {
    await this.afs.doc(`clientModules/${this.client3PDModule.id}`).update({
      inSetup: $event.checked
    });
    this.snackBar.open('Updated in Setup Status', 'Dismiss', { duration: 5000 });
  }
}
