import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ClientModule, DeliverSenseModule, Client, User, ClientModuleBillingRate, StripeTaxRate } from "@deliver-sense-librarian/data-schema";
import moment = require("moment");
import { MatSlideToggleChange } from "@angular/material/slide-toggle";
import { FirestoreUtilities } from '../../../../utilities/firestore-utilities';
import { takeUntil, combineAll, first } from 'rxjs/operators';
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { FirebaseAuthService } from "../../../../auth/services/firebase-auth.service";
import { ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Subject, from } from "rxjs";
import { AngularFirestore } from '@angular/fire/firestore';
import { ClientModuleBillingRateDialogComponent } from '../../../../dialogs/client-module-billing-rate-dialog/client-module-billing-rate-dialog.component';
import { ConfirmDialogComponent } from '../../../../dialogs/confirm-dialog/confirm-dialog.component';
import { StripeService } from '../../../../services/stripe.service';
declare var stripe: any;
declare var elements: any;
@Component({
  selector: 'app-client-billing',
  templateUrl: './client-billing.component.html',
  styleUrls: ['./client-billing.component.scss']
})
export class ClientBillingComponent implements OnInit, OnDestroy {
  client: Client = new Client();
  @Output() formSavedSuccess = new EventEmitter();
  clientForm: FormGroup;
  private _user: User;
  physicalSameAsShipping = new FormControl();
  private _destroy$ = new Subject();
  private clientModules: ClientModule[] = [];
  public availableModules: DeliverSenseModule[] = [];
  taxRates: StripeTaxRate[] = [];
  selectedTaxRate = new FormControl('', Validators.required);
  changingTaxRate = false;
  constructor(private activatedRoute: ActivatedRoute,
    private dialog: MatDialog,
    private snackBar: MatSnackBar,
    private stripeService: StripeService,
    private fb: FormBuilder,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params$ => {
      this._fetchClient(params$['id']);
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }

  private _fetchClient(clientId) {
    this.afs.doc(`clients/${clientId}`)
      .snapshotChanges()
      .subscribe(client$ => {
        this.client = FirestoreUtilities.objectToType(client$);
        this._getClientModules();
        this._getAvailableModules();
        this._setupClientForm();
        this.getTaxRates();
      });
  }
  private async getTaxRates() {
    this.taxRates =  <StripeTaxRate[]>  await this.stripeService.getTaxRateList().pipe(first()).toPromise();
  }
  public async updateClientTaxRate() {
    const selectedTaxRateId = this.selectedTaxRate.value;
    await this.afs.doc(`clients/${this.client.id}`).update({
      defaultTaxRate: selectedTaxRateId
    });
    this.snackBar.open('Successfully updated client tax rate', 'Dismiss', {
      duration: 5000
    });
    this.changingTaxRate = false;
  }
  public getClientTaxRate() {
    if (this.client.defaultTaxRate) {
      const taxRateObject = this.taxRates.find(taxRate => taxRate.id === this.client.defaultTaxRate);
      return taxRateObject;
    }
    return null;
  }
  private _setupClientForm() {
    this.clientForm = this.fb.group({
      name: new FormControl(this.client.name ? this.client.name : '', Validators.required),
      addressLine1: new FormControl(this.client.addressLine1 ? this.client.addressLine1 : '', Validators.required),
      addressLine2: new FormControl(this.client.addressLine2 ? this.client.addressLine2 : ''),
      addressCity: new FormControl(this.client.addressCity ? this.client.addressCity : '', Validators.required),
      addressState: new FormControl(this.client.addressState ? this.client.addressState : '', Validators.required),
      addressPostalCode: new FormControl(this.client.addressPostalCode ? this.client.addressPostalCode : '', Validators.required),
    });
    this.physicalSameAsShipping.valueChanges.subscribe(physicalSameAsShipping$ => {
      this.clientForm.get('billingAddressLine1').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressLine1').updateValueAndValidity();
      this.clientForm.get('billingAddressCity').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressCity').updateValueAndValidity();
      this.clientForm.get('billingAddressState').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressState').updateValueAndValidity();
      this.clientForm.get('billingAddressPostalCode').setValidators(physicalSameAsShipping$ ? null : Validators.required);
      this.clientForm.get('billingAddressPostalCode').updateValueAndValidity();
      this.clientForm.updateValueAndValidity();
    });
  }

  public save() {
    this.clientForm.updateValueAndValidity();
    if (this.clientForm.valid) {
      const _client = this._mapFormToClient();
      this.afs.doc(`clients/${this.client.id}`)
        .update(_client.toJSONObject()).then(() => {
          this.snackBar.open('Successfully updated the client', 'Dismiss', {
            duration: 5000,
          });
          this.formSavedSuccess.emit(true);
        })
    } else {
      this.snackBar.open('Please fill out all required fields.', 'Dismiss', {
        duration: 5000,
      });
    }
  }

  activateModule() {

  }

  async updateBillingEmail(newBillingEmail) {
    await this.afs.doc(`clients/${this.client.id}`).update({
      billingEmail: newBillingEmail
    });
    this.snackBar.open('Client billing email updated successfully.', 'Dismiss', {
      duration: 5000
    })
  }

  private _mapFormToClient(): Client {
    const client = new Client();
    const form = this.clientForm.value;
    client.name = form.name;
    client.primaryContact = this._user.id;
    client.addressLine1 = form.addressLine1;
    client.addressLine2 = form.addressLine2;
    client.addressCity = form.addressCity;
    client.addressState = form.addressState;
    client.addressPostalCode = form.addressPostalCode;
    client.billingAddressLine1 = form.billingAddressLine1;
    client.billingAddressLine2 = form.billingAddressLine2;
    client.billingAddressCity = form.billingAddressCity;
    client.billingAddressState = form.billingAddressState;
    client.billingAddressPostalCode = form.billingAddressPostalCode;
    return client;
  }

  private _getClientModules() {
    this.afs.collection('clientModules', ref => ref
      .where('client', '==', this.client.id))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(clientModules$ => {
        this.clientModules = FirestoreUtilities.mapToType(clientModules$);
        this.getClientModuleBillingRates();
      });
  }

  private getClientModuleBillingRates() {
    const ratesRequest$ = this.clientModules.map(clientModule => {
      return this.afs.collection('clientModuleBillingRates', ref => ref
        .where('clientModule', '==', clientModule.id))
        .snapshotChanges()
    });
    from(ratesRequest$)
      .pipe(combineAll())
      .subscribe(rates$ => {
        const rates = FirestoreUtilities.mergeCollectionToType(rates$);
        this.clientModules.forEach(clientModule => {
          clientModule['billingRates'] = rates.filter(rate => rate.clientModule === clientModule.id);
        });
      });
  }

  private _getAvailableModules() {
    this.afs.collection('deliverSenseModules')
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(dsModules$ => {
        this.availableModules = FirestoreUtilities.mapToType(dsModules$)
      });
  }

  getClientModule(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.clientModules.find(cm => cm.module === dsModule.id);
    return clientMatchingModule ? clientMatchingModule : undefined;
  }

  getBillingRates(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.clientModules.find(cm => cm.module === dsModule.id);
    return clientMatchingModule ? clientMatchingModule['billingRates'] : undefined;
  }

  isClientModuleActive(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.clientModules.find(cm => cm.module === dsModule.id);
    return clientMatchingModule ? clientMatchingModule.active : false;
  }

  async toggleClientModule(event: MatSlideToggleChange, dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.getClientMatchingModule(dsModule);
    if (clientMatchingModule && clientMatchingModule.id) {
      await this.afs.doc(`clientModules/${clientMatchingModule.id}`).update({
        active: event.checked,
        dateUpdated: moment().toDate()
      });
    } else {
      const clientModule = new ClientModule();
      clientModule.module = dsModule.id;
      clientModule.client = this.client.id;
      clientModule.active = event.checked;
      await this.afs.collection('clientModules').add(clientModule.toJSONObject());
    }
    this.snackBar.open('Successfully updated client active modules', 'Dismiss', {
      duration: 5000
    });
  }
  async updateTrial(clientModule: ClientModule, inTrial: boolean, trialStart: any, trialEnd: any, trialRollOver: boolean) {
    await this.afs.doc(`clientModules/${clientModule.id}`).update({
      inTrial: !!inTrial,
      trialStart: trialStart ? moment(trialStart).toDate() : null,
      trialEnd: trialEnd ? moment(trialEnd).toDate() : null,
      trialRollOver: !!trialRollOver
    });
    this.snackBar.open('Update client module trial', 'Dismiss', {
      duration: 5000
    })
  }
  getClientMatchingModule(dsModule) {
    return this.clientModules.find(cm => cm.module.toString() === dsModule.id);
  }

  activatedInLast24Hours(dsModule: DeliverSenseModule) {
    const clientMatchingModule = this.getClientMatchingModule(dsModule);
    if (clientMatchingModule && clientMatchingModule.active && clientMatchingModule.dateUpdated) {
      return moment(clientMatchingModule.dateUpdated.toDate()).isSameOrAfter(moment().subtract(24, "hours"));
    } else {
      return false;
    }
  }

  openBillingRateDialog(clientModule: ClientModule, billingRate?: ClientModuleBillingRate) {
    this.dialog.open(ClientModuleBillingRateDialogComponent, {
      disableClose: true,
      data: {
        clientModuleBillingRate: billingRate,
        client: this.client,
        clientModule: clientModule
      }
    })
  }
  removeBillingRate(billingRate: ClientModuleBillingRate) {
    const confirmDialog = this.dialog.open(ConfirmDialogComponent,
      {
        data: {
          title: 'Confirm Remove',
          message: 'Are you sure you want to remove this billing rate?',
          action: 'Yes, remove'
        }
      });
    confirmDialog.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.afs.doc(`clientModuleBillingRates/${billingRate.id}`).delete();
        this.snackBar.open('Rate removed successfully');
      }
    })
  }

  nextBillDate() {

  }

  nextInvoiceAmount() {

  }

  getCurrentBalanceDueDate() {

  }

  getCurrentBalance() {

  }
}
