import { Component, OnInit, OnDestroy } from '@angular/core';
import { FirebaseAuthService } from 'app/auth/services/firebase-auth.service';
import { MatDialog, MatSnackBar } from '@angular/material';
import { ActivatedRoute } from '@angular/router';
import { takeUntil } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import { ThirdParty } from '@deliver-sense-librarian/data-schema';
import { AngularFirestore } from '@angular/fire/firestore';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-client-organization',
  templateUrl: './client-organization.component.html',
  styleUrls: ['./client-organization.component.scss']
})
export class ClientOrganizationComponent implements OnInit, OnDestroy {
  client: any;
  thirdParties: ThirdParty[];
  _destroy$ = new Subject();
  constructor(private auth: FirebaseAuthService,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.activatedRoute.parent.params.subscribe(params$ => {
      this.afs.doc(`clients/${params$['id']}`)
        .snapshotChanges()
        .pipe(takeUntil(this._destroy$))
        .subscribe(client$ => {
          this.client = FirestoreUtilities.objectToType(client$);
        });
    })
  }

  ngOnDestroy(): void {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
}
