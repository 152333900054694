import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { Client, DeliverSenseModule, ClientDayCharge, ClientModule, ClientInvoice } from '@deliver-sense-librarian/data-schema';
import { MatPaginator, MatSort, MatTableDataSource, MatDialog, MatSnackBar } from '@angular/material';
import { FormControl, Validators } from '@angular/forms';
import { Subject, from } from 'rxjs';
import { Papa } from 'ngx-papaparse';
import { FirebaseApp } from 'angularfire2';
import { LoadingDialogService } from 'app/services/loading-dialog.service';
import moment = require('moment');
import { takeUntil, combineAll } from 'rxjs/operators';
import { FirestoreUtilities } from 'app/utilities/firestore-utilities';
import _ = require('lodash');
import { ReasonPromptDialogComponent } from 'app/dialogs/reason-prompt-dialog/reason-prompt-dialog.component';
import { Store } from '@ngrx/store';
import { AngularFirestore } from '@angular/fire/firestore';

@Component({
  selector: 'app-client-invoices',
  templateUrl: './client-invoices.component.html',
  styleUrls: ['./client-invoices.component.scss']
})
export class ClientInvoicesComponent implements OnInit {
  @Input() client: Client;
  @Input() dsModules: DeliverSenseModule[];
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = [
    'id',
    'invoicedDate',
    'status',
    'emailTo',
    'subTotal',
    'tax',
    'fees',
    'credits',
    'processorInvoiceId',
    'startDate',
    'endDate',
    'dueDate'];
  public startDate = new FormControl(null, Validators.required);
  public endDate = new FormControl(null, [Validators.required]);
  public clientInvoices: ClientInvoice[] = [];
  private _destroy$ = new Subject();
  constructor(private store: Store<any>,
    private papa: Papa,
    private firebaseApp: FirebaseApp,
    private dialog: MatDialog,
    private loadingService: LoadingDialogService,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore,
    private _cdr: ChangeDetectorRef) { }

  ngOnInit() {
  }
  runSearch() {
    this.clientInvoices = null;
    const start = moment(this.startDate.value).startOf('day').toDate();
    const end = moment(this.endDate.value).endOf('day').toDate();
    this.afs.collection('clientInvoices', ref => ref
      .where('client', '==', this.client.id)
      .where('invoicedDate', '>=', start)
      .where('invoicedDate', '<=', end)
      .orderBy('invoicedDate', 'desc'))
      .snapshotChanges()
      .pipe(takeUntil(this._destroy$))
      .subscribe(clientInvoices$ => {
        this.clientInvoices = FirestoreUtilities.mapToType(clientInvoices$);
        this.tableData = new MatTableDataSource(this.clientInvoices);
        this.tableData.paginator = this.paginator;
        this.tableData.sort = this.sort;
        this._cdr.detectChanges();
      });
  }
  getDeliverSenseModule(clientModule: ClientModule) {
    if (clientModule) {
      const module = this.dsModules.find(dsModule => dsModule.id === clientModule.module);
      return module ? module.name : '';
    }
    return '';
  }
  getMaxEndDate() {
    if (this.startDate.valid) {
      return moment(this.startDate.value).add(12, 'months').toDate();
    }
  }
}
