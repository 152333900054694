import {ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatPaginator, MatSnackBar, MatSort, MatTableDataSource} from "@angular/material";
import {Location, User} from "@deliver-sense-librarian/data-schema";
import {FirebaseAuthService} from "../../../auth/services/firebase-auth.service";
import {Papa} from "ngx-papaparse";
import {AngularFirestore} from '@angular/fire/firestore';
import {map, takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../utilities/firestore-utilities";
import {Subject} from "rxjs";
import {animate, state, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-client-list-component',
    templateUrl: './client-list.component.html',
    styleUrls: ['./client-list.component.scss'],
    animations: [
        trigger('detailExpand', [
            state('collapsed', style({height: '0px', minHeight: '0'})),
            state('expanded', style({height: '*'})),
            transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
        ]),
    ],
})
export class ClientListComponent implements OnInit, OnDestroy {
    @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
    @ViewChild(MatSort, {static: true}) sort: MatSort;
    public tableData: MatTableDataSource<Location>;
    public displayedColumns: string[] = ['id', 'name', 'addressLine1', 'addressLine2', 'city', 'state', 'zip', 'email', 'expandTrigger'];
    public expandedElement: any | null;
    private _user: User;
    private _destroy$ = new Subject();

    constructor(private auth: FirebaseAuthService,
                private papa: Papa,
                private dialog: MatDialog,
                private snackBar: MatSnackBar,
                private afs: AngularFirestore,
                private _cdr: ChangeDetectorRef) {
    }

    ngOnInit() {

                this._fetchClients();

    }

    ngOnDestroy() {
        this._destroy$.next(true);
        this._destroy$.complete();
    }

    private _fetchClients() {
        this.afs.collection('clients')
            .snapshotChanges()
            .pipe(takeUntil(this._destroy$))
            .subscribe(clients$ => {
                this.tableData = FirestoreUtilities.mapToType(clients$);
            });
    }

    applyFilter(filterValue: string) {
        this.tableData.filter = filterValue.trim().toLowerCase();
        if (this.tableData.paginator) {
            this.tableData.paginator.firstPage();
        }
    }
}
