import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AngularFirestore, AngularFirestoreCollection} from '@angular/fire/firestore';
import {MatDialog} from "@angular/material/dialog";
import {MatSnackBar} from "@angular/material/snack-bar";
import {Client, ClientThirdParty, ClientPosSystem, ThirdParty, PosSystem} from "@deliver-sense-librarian/data-schema";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {BehaviorSubject, Subject} from "rxjs";
import {takeUntil} from "rxjs/operators";
import {FirestoreUtilities} from "../../../../../utilities/firestore-utilities";
import {MatPaginator} from "@angular/material/paginator";
import {MatSort} from "@angular/material/sort";
import {MatTableDataSource} from "@angular/material/table";
import {ConfirmDialogComponent} from "../../../../../dialogs/confirm-dialog/confirm-dialog.component";

@Component({
  selector: 'app-client-vendor-mappings',
  templateUrl: './client-vendor-mappings.component.html',
  styleUrls: ['./client-vendor-mappings.component.scss']
})
export class ClientVendorMappingsComponent implements OnInit, OnDestroy {
  @Input() client: Client;
  @Input() type: '3pd' | 'posSystem';
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  public tableData: MatTableDataSource<any>;
  public displayedColumns: string[] = ['vendor', 'clientVendorId', 'active', 'edit'];
  public vendorInEdit: BehaviorSubject<any> = new BehaviorSubject(null);
  public posSystems: PosSystem[] = [];
  public thirdParties: ThirdParty[] = [];
  public vendorForm: FormGroup;
  private destroy$ = new Subject();
  private collectionRef: AngularFirestoreCollection<any>;
  private existingVendors: any[];
  private collectionName: string;

  constructor(private afs: AngularFirestore,
              private dialog: MatDialog,
              private fb: FormBuilder,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    this.collectionName = this.type === '3pd' ? 'clientThirdParties' : 'clientPosSystems';
    this.collectionRef = this.afs.collection(this.collectionName);
    this.getGlobalResources();
    this.getExistingVendorsOfType();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  private setUpClientVendorForm() {
    this.vendorInEdit.subscribe((vendor$: any) => {
      if (vendor$) {
        this.vendorForm = this.fb.group({
          client: new FormControl(this.client.id, Validators.required),
          clientVendorId: new FormControl(vendor$.clientVendorId, Validators.required),
          active: new FormControl(vendor$.active),
          vendor: new FormControl(vendor$.thirdParty ? vendor$.thirdParty : vendor$.posSystem ? vendor$.posSystem : null, Validators.required)
        });
      }
    })
  }

  private getGlobalResources() {
    switch (this.type) {
      case "3pd":
        this.get3pds();
        break;
      case "posSystem":
        this.getPosOptions();
        break;
    }
  }

  public setMappingInEdit(vendor?: any) {
    this.vendorInEdit.next(vendor ? vendor : this.type === '3pd' ? new ClientThirdParty() : new ClientPosSystem());
    this.setUpClientVendorForm();
  }

  private get3pds() {
    this.afs.collection('thirdParties')
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(thirdParties$ => {
        this.thirdParties = FirestoreUtilities.mapToType(thirdParties$);
      })
  }

  private getPosOptions() {
    this.afs.collection('posSystems')
      .snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(posSystems$ => {
        this.posSystems = FirestoreUtilities.mapToType(posSystems$);
      })
  }

  getThirdPartyName(thirdPartyId: string) {
    const thirdParty = this.thirdParties.find(_thirdParty => _thirdParty.id === thirdPartyId)
    return thirdParty ? thirdParty.name : '';
  }

  getPosName(posId: string) {
    const pos = this.posSystems.find(_pos => _pos.id === posId)
    return pos ? pos.name : '';
  }

  async save() {
    if (this.vendorForm.valid) {
      const formValues = this.vendorForm.value;
      const conversion = {
        clientVendorId: formValues.clientVendorId,
        active: formValues.active,
        client: this.client.id
      };
      if (this.type === '3pd') {
        conversion['thirdParty'] = formValues.vendor;
      } else {
        conversion['posSystem'] = formValues.vendor;
      }
      const collection = this.type === '3pd' ? 'clientThirdParties' : 'clientPosSystems';
      if (this.vendorInEdit.value.id) {
        await this.afs.doc(`${collection}/${this.vendorInEdit.value.id}`).update(
          conversion
        )
      } else {
        await this.afs.collection(`${collection}`).add(
          conversion
        )
      }
      this.snackBar.open(`Client Vendor ${this.vendorInEdit.value.id ? 'Updated' : 'Created'}`, 'Dismiss', {
        duration: 5000
      });
      this.vendorForm = null;
      this.vendorInEdit.next(null);
    } else {
      this.snackBar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      });
    }
  }

  private getExistingVendorsOfType() {
    this.afs.collection(this.collectionName, ref => ref
      .where('client', '==', this.client.id)).snapshotChanges()
      .pipe(takeUntil(this.destroy$))
      .subscribe(vendors$ => {
        this.existingVendors = FirestoreUtilities.mapToType(vendors$);
        this.tableData = new MatTableDataSource(this.existingVendors);
      })
  }

  public cancel() {
    this.vendorForm = null;
    this.vendorInEdit.next(null);
  }

  async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this client vendor?',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        await this.collectionRef.doc(`${this.vendorInEdit.value.id}`).delete();
        this.vendorInEdit.next(null);
        this.snackBar.open('Deletion successful', 'Dismiss', {
          duration: 5000
        })
      }
    });
  }
}
