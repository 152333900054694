import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatSnackBar } from '@angular/material';
import { BehaviorSubject, Subject } from "rxjs";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { map, take, first } from "rxjs/operators";
import { Client, User } from "@deliver-sense-librarian/data-schema";
import { LoadingDialogService } from "../../services/loading-dialog.service";
import { FirestoreUtilities } from "../../utilities/firestore-utilities";
import { environment } from "../../../environments/environment";
import { Store } from '@ngrx/store';
import {
  SetAccountClientAction,
  UnauthenticateUserAction,
  UserAuthenticationSuccessAction
} from "../../redux/custom-states/uiState/ui-state-actions";

@Injectable()
export class FirebaseAuthService {
  private _user: User;
  private userSet = false;
  private inSignup = false;
  public authUser = new BehaviorSubject(null);
  private userSubscription;
  private loggedIn = true;
  private loggingOut = new Subject();

  constructor(private afAuth: AngularFireAuth,
    private afs: AngularFirestore,
    private router: Router,
    private snackBar: MatSnackBar,
    private store: Store<any>,
    private loadingService: LoadingDialogService,
    protected http: HttpClient) {
    this._initializeAuthState();

  }
  private async _initializeAuthState() {
    const appVersion = JSON.parse(localStorage.getItem('applicationVersion'));
    if (!appVersion || appVersion !== environment.applicationVersion) {
      this.signOut();
    } else {
      const user = await this._checkForAuthUser();
    }
  }

  public async login(email, password, invitationId?) {
    const url = `${environment.apiUrl}login`;
    this.loadingService.isLoading(true, 'Authenticating...');
    try {
      const customToken = await this.http.post(url, { email, password, invitationId }).pipe(first(), map(response => response['token'])).toPromise();
      const authUser = await this.setAuthenticationState(customToken);
      this.loadingService.isLoading(false);
      this.snackBar.open('Login successful. Welcome back!', 'Dismiss', {
        duration: 5000
      });
      return authUser;
    } catch (e) {
      this.loadingService.isLoading(false);
      throw new Error(e.message);
    }
  }
  public async setAuthenticationState(customToken) {
    try {
      const credential = await this.afAuth.auth.signInWithCustomToken(customToken);
      return await this.setApplicationCredentials(credential);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  private async _checkForAuthUser() {
    if (!this.userSet) {
      const authUser = await this.afAuth.authState
        .pipe(map(user$ => user$), first())
        .toPromise();
      if (authUser) {
        const user = await this.setAuthUserState(authUser.uid);
        this.userSet = true;
        return user;
      } else {
        this.signOut();
      }
    }
  }
  public async setAuthUserState(userId) {
    const user$ = await this.afs.doc(`users/${userId}`).snapshotChanges().pipe(first()).toPromise();
    const user = FirestoreUtilities.objectToType(user$);
    this.store.dispatch(new UserAuthenticationSuccessAction(user));
    return user;
  }
  private async setApplicationCredentials(credential) {
    try {
      const user = await this.setAuthUserState(credential.user.uid);
      this.loadingService.isLoading(false);
      return user;
    } catch (e) {
      this.loadingService.isLoading(false);
      this.snackBar.open('Error loading your account. Please refresh and try again.', 'Dismiss', {
        duration: 5000
      });
    }
  }

  public async signOut() {
    this.store.dispatch(new UnauthenticateUserAction());
    await this.afAuth.auth.signOut();
    this.loggingOut.next(true);
    this.authUser = new BehaviorSubject<any>(null);
    this._user = null;
  }

  private getAuthHeader(token): HttpHeaders {
    return new HttpHeaders().set('Authorization', `Bearer ${token}`);
  }


}
