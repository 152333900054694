import {FormsModule} from '@angular/forms';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {AdminLayoutComponent} from './admin-layout.component';
import {MaterialModule} from '../../material-module.module';
import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {MalihuScrollbarModule, MalihuScrollbarService} from 'ngx-malihu-scrollbar';
import {DialogsModule} from 'app/dialogs/dialogs.module';
import {FlexLayoutModule} from "@angular/flex-layout";
import {DsSharedModule} from "../../shared/ds-shared.module";
import {PerfectScrollbarModule} from "ngx-perfect-scrollbar";
import {LoadingBarRouterModule} from "@ngx-loading-bar/router";
import {HeaderModule} from "../../components/structural/header/header.module";
import {SidebarModule} from "../../components/structural/sidebar/sidebar.module";
import {AccordionModule} from "../../components/structural/menu-accordion/accordion.module";
import {OptionsModule} from "../../components/structural/options/options.module";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        RouterModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        DialogsModule,
        FlexLayoutModule,
        MalihuScrollbarModule.forRoot(),
        DsSharedModule,
        PerfectScrollbarModule,
        LoadingBarRouterModule,
        HeaderModule,
        SidebarModule,
        AccordionModule,
        OptionsModule
    ],
    declarations: [
        AdminLayoutComponent,
    ],
    exports: [
        AdminLayoutComponent,
    ],
    providers: [MalihuScrollbarService]
})
export class AdminLayoutModule {
}
