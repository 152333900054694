import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { AngularFirestore } from '@angular/fire/firestore';
import { FirestoreUtilities } from "../../../utilities/firestore-utilities";
import * as firebase from "firebase";
import { first } from "rxjs/operators";
import { MatTableDataSource, MatPaginator, MatSort, MatDialog, MatSnackBar } from '@angular/material';
import { LoadingDialogService } from '../../../services/loading-dialog.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ConfirmDialogComponent } from '../../../dialogs/confirm-dialog/confirm-dialog.component';
import * as _ from 'lodash';
import { downloadDataAsFile } from '../scripter/scripter.component';
import * as moment from 'moment';

@Component({
  selector: 'app-fireql',
  templateUrl: './fireql.component.html',
  styleUrls: ['./fireql.component.scss']
})
export class FireqlComponent implements OnInit {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  public displayedColumns: string[] = ['id', 'select'];
  whereClauses = new FormArray([]);
  private queryForm: FormGroup;
  private queryResults: any;
  public operators = ['==', '>=', '<=', '<', '>'];
  queryData = new MatTableDataSource([]);
  selection = new SelectionModel<any>(true, []);

  //   editorOptions = { theme: 'vs-dark', language: 'javascript', scrollBeyondLastLine: false };
  //   code = `
  //   return store.collection('clientThirdPartyTransactionConversions', ref => ref
  // .where('client', '==', 'QKdAnf5u8g5bvEBRVnll'));`;

  analyzeOperation = new FormControl('', Validators.required);
  columnToProcess = new FormControl('', Validators.required);
  analysisOperations = ['sum', 'count'];
  analysisResult: any;
  constructor(private fb: FormBuilder,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingDialogService,
    private dialog: MatDialog,
    private snackbar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    this.queryForm = this.fb.group({
      collection: new FormControl('', Validators.required),
      docId: new FormControl(),
      whereClauses: new FormArray([]),
      orderBy: new FormControl(),
      query: new FormControl()
    });
    this.queryData.paginator = this.paginator;
    this.queryData.sort = this.sort;
    this.cdr.detectChanges();
  }
  /** Whether the number of selected elements matches the total number of rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.queryData.data.length;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.queryData.data.forEach(row => this.selection.select(row));
  }

  /** The label for the checkbox on the passed row */
  checkboxLabel(row?: any): string {
    if (!row) {
      return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
    }
    return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
  }
  // onEditorInit() {
  //   console.log('editor loaded');
  //   // Manual triggering of change  detection is required to stop showing loading spinner
  //   this.cdr.detectChanges();
  // }

  // async runQuery() {
  //   const afs = this.afs;
  //   const f = new Function('store', this.code);
  //   this.loadingService.isLoading(true, 'Running query');
  //   const results = await f(afs).snapshotChanges().pipe(first()).toPromise();
  //   this.queryResults = FirestoreUtilities.mapToType(results);
  //   Object.keys(this.queryResults).forEach(columnName => {
  //     if (columnName !== 'id') {
  //       this.displayedColumns.push(columnName);
  //     }
  //   });
  //   debugger;
  //   this.queryData = new MatTableDataSource(this.queryResults);
  //   setTimeout(() => {
  //     this.queryData.paginator = this.paginator;
  //     this.queryData.sort = this.sort;
  //     this.cdr.detectChanges();
  //   });
  // }

  addWhereClause() {
    const whereClauses = this.queryForm.get('whereClauses') as FormArray;
    whereClauses.push(new FormGroup({
      key: new FormControl('', Validators.required),
      operator: new FormControl('', Validators.required),
      value: new FormControl('', Validators.required)
    }));
  }

  removeWhereClause(index) {
    const whereClauses = this.queryForm.get('whereClauses') as FormArray;
    whereClauses.removeAt(index);
  }
  getColumns() {
    return this.displayedColumns.filter(name => name !== 'select');
  }
  executeQuery() {
    if (this.queryForm.valid) {
      this.analysisResult = null;
      this.analyzeOperation.reset();
      this.columnToProcess.reset();
      const queryValues = this.queryForm.value;
      const collection = queryValues.collection;
      const docId = queryValues.docId;
      const whereClauses = queryValues.whereClauses;
      const orderBy = queryValues.orderBy;
      this.loadingService.isLoading(true, 'Running query');
      if (docId) {
        const collectionRef = this.afs.collection(collection);
        const finalQuery = collectionRef.doc(docId);
        finalQuery.snapshotChanges().subscribe(results => {
          this.queryResults = FirestoreUtilities.objectToType(results);
          this.populateTable();
        })
      } else {
        if (whereClauses.length > 0) {
          let collectionRef: firebase.firestore.Query = this.afs.collection(collection).ref;
          whereClauses.forEach(whereClause => {
            collectionRef = collectionRef.where(whereClause.key, whereClause.operator, whereClause.value);
          });
          if (orderBy) {
            collectionRef = collectionRef.orderBy(orderBy, 'desc');
          }
          this.afs.collection(collection, ref => collectionRef).snapshotChanges()
            .subscribe(results => {
              this.queryResults = FirestoreUtilities.mapToType(results);
              this.populateTable();
              this.loadingService.isLoading(false);
            })
        } else {
          this.afs.collection(collection).snapshotChanges().subscribe(results => {
            this.queryResults = FirestoreUtilities.mapToType(results);
            this.populateTable();
          })
        }
      }
    }
  }
  runAnalysis() {
    switch (this.analyzeOperation.value) {
      case 'sum':
        this.analysisResult = this.queryResults.reduce((sum, doc) => {
          const docColumnValue = doc[this.columnToProcess.value];
          if (!!docColumnValue && !isNaN(docColumnValue)) {
            return sum += docColumnValue;
          }
          return sum;
        }, 0);
        break;
      case 'count':
        this.analysisResult = this.queryResults.reduce((sum, doc) => {
          const docColumnValue = doc[this.columnToProcess.value];
          if (docColumnValue !== undefined && docColumnValue !== null) {
            sum++;
            return sum;
          }
          return sum;
        }, 0)
        break;
    }
  }
  populateTable() {
    if (this.queryResults.length > 0) {
      this.queryResults.forEach(doc => {
        Object.keys(doc).forEach(key => {
          if (doc[key] instanceof firebase.firestore.Timestamp) {
            doc[key] = doc[key].toDate();
          }
        })
      })
      this.displayedColumns = ['id', 'select'];
      Object.keys(this.queryResults[0]).forEach(columnName => {
        if (columnName !== 'id' && columnName !== 'select') {
          this.displayedColumns.push(columnName);
        }
      });
      this.queryData = new MatTableDataSource(this.queryResults);
      setTimeout(() => {
        this.queryData.paginator = this.paginator;
        this.queryData.sort = this.sort;
        this.cdr.detectChanges();
      }, 1000);
      this.loadingService.isLoading(false);
    }
  }
  getQueryResultsKeys() {
    return Object.keys(this.queryResults[0]);
  }

  async deleteRows() {
    const confirmRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete these records?',
        action: 'Yes, Delete.'
      }
    });
    confirmRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.loadingService.isLoading(true, 'Deleting Documents');
        const chunks = _.chunk(this.selection.selected, 400);
        await Promise.all(chunks.map(async (chunk) => {
          const batch = this.afs.firestore.batch();
          chunk.forEach(doc => {
            batch.delete(this.afs.doc(`${this.queryForm.get('collection').value}/${doc.id}`).ref);
          });
          return await batch.commit();
        }));
        this.loadingService.isLoading(false);
        this.snackbar.open('Documents deleted successfully', 'Dismiss', {
          duration: 5000
        });
        this.executeQuery();
      }
    });
  }
  sortDataByColumn(column: string) {
    this.queryResults.sort((a, b) => {
      return a[column] > b[column] ? 1 : -1;
    })
  }
  parseQuery() {
    const query = this.queryForm.get('query').value;
    const sections = query.split(' ')
  }

  downloadResults() {
    const data = JSON.stringify(this.queryResults);
    downloadDataAsFile(data, 'queryResults', 'json');
  }

  sendDataTo(path, key) {
    const value = this.queryResults;
    this.queryResults.forEach(doc => {
      delete doc.id;
      doc.dateCreated = moment().toDate();
      doc.dateUpdated = moment().toDate();
      if (doc.algorithm) {
        doc.algorithm.forEach(algorithm$ => {
          algorithm$.dateCreated = moment().toDate();
          algorithm$.dateUpdated = moment().toDate();
        })
      }
    })
    const data = {};
    data[key] = value;
    this.afs.doc(path).update(data);
  }
}
