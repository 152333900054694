import { Component, EventEmitter, Input, OnInit, Output, OnDestroy } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import {
  ClientPosTransactionConversion,
  ClientThirdPartyTransactionConversion,
  dataConverterParameters,
  ThirdParty, thirdPartyTransactionFields
} from "@deliver-sense-librarian/data-schema";
import * as _ from "lodash";
import { MatDialog } from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AngularFirestore } from "@angular/fire/firestore";
import { ConversionFunction } from "@deliver-sense-librarian/data-schema/dist/models/classes/conversion-function";
import { Subject } from "rxjs";
import { ConfirmDialogComponent } from 'app/dialogs/confirm-dialog/confirm-dialog.component';

@Component({
  selector: 'app-tpd-conversion',
  templateUrl: './tpd-conversion.component.html',
  styleUrls: ['./tpd-conversion.component.scss']
})
export class TpdConversionComponent implements OnInit, OnDestroy {
  @Input() thirdParty: ThirdParty;
  @Input() conversion: ClientThirdPartyTransactionConversion;
  @Input() unavailableFields: string[] = [];
  @Input() index: number;
  @Output() complete = new EventEmitter();
  public availableFields = thirdPartyTransactionFields;
  public conversionForm: FormGroup;
  public conversionMethods = Object.keys(dataConverterParameters);
  public parameterOptions = [];
  public editorOptions = { theme: 'vs-dark', language: 'javascript', scrollBeyondLastLine: false };
  public saving: boolean;
  public conversionAlgorithmCode = `
  // @NOTE use row['fieldName'] to access the values of the incoming object
  // @NOTE use dataConverter.<methodName>() to access dataConverter
  `;
  public newConversion = false;
  public availableParameters: string[];
  private _destroy$ = new Subject();

  constructor(
    private dialog: MatDialog,
    private fb: FormBuilder,
    private snackBar: MatSnackBar,
    private afs: AngularFirestore) {
  }

  ngOnInit() {
    if (this.unavailableFields && this.unavailableFields.length) {
      this.availableFields = this.availableFields.filter(field => {
        return !this.unavailableFields.find(unavailableField => unavailableField === field);
      })
      if (this.conversion) {
        this.availableFields.push(this.conversion.keyOut);
      }
    }
    if (!this.conversion) {
      this.conversion = new ClientThirdPartyTransactionConversion();
      this.newConversion = true;
    } else {
      this.index = this.thirdParty.reportConversionMappings.indexOf(this.conversion);
    }
    this.setupMappingForm();
  }
  ngOnDestroy() {
    this._destroy$.next(true);
    this._destroy$.complete();
  }
  public getParameterName(index) {
    const methodParams = dataConverterParameters[this.conversionForm.get('method').value];
    if (methodParams) {
      return Object.keys(methodParams)[index];
    }
    return '';
  }

  private setupMappingForm() {
    this.conversionForm = this.fb.group({
      parent: new FormControl(this.thirdParty.id, Validators.required),
      keyIn: new FormControl(this.conversion.keyIn, Validators.required),
      keyOut: new FormControl(this.conversion.keyOut, Validators.required),
      default: new FormControl(this.conversion.algorithm && this.conversion.algorithm[0].default ? this.conversion.algorithm[0].default : ''),
      method: new FormControl(this.conversion.algorithm && this.conversion.algorithm[0].method ? this.conversion.algorithm[0].method : ''),
      code: new FormControl(this.conversion.algorithm && this.conversion.algorithm[0].code ? this.conversion.algorithm[0].code :
        `/**
        * Access row properties via row['fieldName']
        **/
      `),
      methodParameters: new FormArray([]),
      fields: new FormArray([])
    });
    this.setupFormListeners();
    this.conversionForm.get('method').updateValueAndValidity();
    this.populateFormArrays(this.conversion);
  }

  private setupFormListeners() {
    this.conversionForm.get('method').valueChanges.subscribe(method$ => {
      const methodParams = dataConverterParameters[method$];
      if (methodParams) {
        this.availableParameters = Object.keys(methodParams);
      }
    });
  }

  public addFormArrayGroup(formArrayName: 'methodParameters' | 'fields') {
    const methodParameters = this.conversionForm.get(formArrayName) as FormArray;
    methodParameters.push(new FormGroup({
      value: new FormControl()
    }));
  }

  public removeFormArrayGroup(formArrayName: 'methodParameters' | 'fields', index) {
    const methodParameters = this.conversionForm.get(formArrayName) as FormArray;
    methodParameters.removeAt(index);
  }

  private mapFormToConversion(formValues) {
    const newConversion = new ClientThirdPartyTransactionConversion();
    const newAlgorithm = new ConversionFunction();
    newConversion.keyOut = formValues.keyOut;
    newConversion.keyIn = formValues.keyIn;
    if (formValues.method) {
      newConversion.algorithm = [newAlgorithm];
      if (formValues.method === 'code') {
        newAlgorithm.code = formValues.code;
      }
      newAlgorithm.method = formValues.method;
      newAlgorithm.methodParameters = formValues.methodParameters ? _.map(formValues.methodParameters, 'value') : [];
      newAlgorithm.fields = formValues.fields ? _.map(formValues.fields, 'value') : [];
      newAlgorithm.default = formValues.default;
      //@ts-ignore
      newConversion.algorithm[0] = newAlgorithm.toJSONObject();
    } else {
      newConversion.algorithm = null;
    }
    return newConversion;
  }

  private populateFormArrays(conversion) {
    if (conversion.algorithm && conversion.algorithm[0]) {
      if (conversion.algorithm[0].methodParameters) {
        conversion.algorithm[0].methodParameters.forEach(methodParameter => {
          const methodParameters = this.conversionForm.get('methodParameters') as FormArray;
          methodParameters.push(new FormGroup({
            value: new FormControl(methodParameter)
          }));
        });
      } else {
        const methodParameters = this.conversionForm.get('methodParameters') as FormArray;
        methodParameters.push(new FormGroup({
          value: new FormControl('')
        }));
      }
      if (conversion.algorithm[0].fields) {
        conversion.algorithm[0].fields.forEach(field => {
          const fields = this.conversionForm.get('fields') as FormArray;
          fields.push(new FormGroup({
            value: new FormControl(field)
          }));
        });
      } else {
        const fields = this.conversionForm.get('fields') as FormArray;
        fields.push(new FormGroup({
          value: new FormControl('')
        }));
      }
    }
  }

  async delete() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Confirm Delete',
        message: 'Are you sure you want to delete this mapping?',
        action: 'Yes, Delete.'
      }
    });
    dialogRef.afterClosed().subscribe(async (confirmed) => {
      if (confirmed) {
        this.thirdParty.reportConversionMappings.splice(this.index, 1);
        await this.afs.doc(`thirdParties/${this.thirdParty.id}`).update({
          reportConversionMappings: this.thirdParty.reportConversionMappings
        })
        await this.snackBar.open(`Conversion ${this.conversion.id ? 'Updated' : 'Created'}`, 'Dismiss', {
          duration: 5000
        });
      }
      this.complete.emit(true);
    });
  }

  async save() {
    if (this.conversionForm.valid) {
      this.saving = true;
      const conversion = this.mapFormToConversion(this.conversionForm.value);
      if (this.index) {
        this.thirdParty.reportConversionMappings[this.index] = conversion.toJSONObject();
      } else {
        this.thirdParty.reportConversionMappings.push(conversion.toJSONObject());
      }
      const conversionMappings = this.thirdParty.reportConversionMappings;
     await this.afs.doc(`thirdParties/${this.thirdParty.id}`).update({
        reportConversionMappings: conversionMappings
      })
      await this.snackBar.open(`Conversion ${this.conversion.id ? 'Updated' : 'Created'}`, 'Dismiss', {
        duration: 5000
      });
      this.complete.emit(true);
    } else {
      this.snackBar.open('Please fill out all required fields', 'Dismiss', {
        duration: 5000
      });
    }
  }

  getAvailableParameterHelperText() {
    if (this.availableParameters && this.availableParameters.length > 0) {
      return this.availableParameters.reduce((result, current) => {
        if (current) {
          result = result + this.availableParameters.indexOf(current) + ': ' + current + ' \n';
        }
        return result;
      }, '')
    }
  }
}
