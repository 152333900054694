import { FirestoreUtilities } from './../../utilities/firestore-utilities';
import { takeUntil } from 'rxjs/operators';
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormControl, Validators, NgForm } from '@angular/forms';
import { MatSnackBar } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { LoadingDialogService } from '../../services/loading-dialog.service';
import { Subject } from 'rxjs';
import { EditorComponent } from '@tinymce/tinymce-angular';

@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss']
})
export class ContentEditorComponent implements OnInit, OnDestroy {
  contentTitles = []
  selectedStaticContent = new FormControl();
  newStaticContentId = new FormControl('', Validators.required);
  editorInit = {  height: 700, width: 1000 }
  public editorContent = null
  private destroy$ = new Subject();
  staticContentObjects = [];
  creatingNewStaticContent = false;
  constructor(
    private snackBar: MatSnackBar,
    private loadingService: LoadingDialogService,
    private afs: AngularFirestore) { }

  ngOnInit() {
    this.afs.collection('staticContents').snapshotChanges().pipe(takeUntil(this.destroy$))
      .subscribe(staticContents$ => {
        this.staticContentObjects = FirestoreUtilities.mapToType(staticContents$);
        this.contentTitles = this.staticContentObjects.map(staticContentObject => staticContentObject.id).filter(item => !!item);
      });
    this.selectedStaticContent.valueChanges.subscribe(staticContentSelection => {
      this.editorContent = null;
      setTimeout(() => {
        this.editorContent = this.staticContentObjects.find(staticContentObject => staticContentObject.id === staticContentSelection).value;
      }, 500);
    })
  }
  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
  updateValue({ event, editor }: any) {
    this.editorContent = editor.getContent();
  }

  async updateStaticContent($event) {
    await this.afs.doc(`staticContents/${this.selectedStaticContent.value}`).set({
      value:this.editorContent
    });
    this.snackBar.open(`${this.selectedStaticContent.value} updated successfully.`, 'Dismiss', {
      duration: 5000
    });
  }
  startNewStaticContentItem() {
    this.editorContent = '';
    this.selectedStaticContent.reset();
    this.creatingNewStaticContent = true;
  }
  cancelNewStaticContent() {
    this.creatingNewStaticContent = false;
  }
  async createStaticContent() {
    if (this.newStaticContentId.valid) {
      await this.afs.doc(`staticContents/${this.newStaticContentId}`).set({
        value:this.editorContent
      });
      this.snackBar.open(`${this.selectedStaticContent.value} updated successfully.`, 'Dismiss', {
        duration: 5000
      });
    } else {
      this.snackBar.open('Please add an id for the static content');
    }
  }
}
