import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentEditorComponent } from './content-editor.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from "@angular/flex-layout";
import { MaterialModule } from "../../material-module.module";
import { DsSharedModule } from '../../shared/ds-shared.module';
import { EditorModule } from '@tinymce/tinymce-angular';

@NgModule({
  declarations: [ContentEditorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    FlexLayoutModule,
    MaterialModule,
    DsSharedModule,
    EditorModule
  ]
})
export class ContentEditorModule { }
